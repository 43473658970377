import { Controller } from "stimulus";

export default class extends Controller {
  static outlets = ["visio-2", "pre-visio-diagnostics"];

  static targets = [
    "availableAudioOutputDevicesSelect",
    "availableAudioInputDevicesSelect",
    "availableVideoDevicesSelect",
  ];

  refreshOutputAudioDevices(audioDevices) {
    if (this.hasAvailableAudioOutputDevicesSelectTarget) {
      const options = Array.from(
        this.availableAudioOutputDevicesSelectTarget.options,
      );
      audioDevices.forEach((audioDevice) => {
        if (
          audioDevice.name &&
          !options.find((o) => o.value == audioDevice.id)
        ) {
          this.availableAudioOutputDevicesSelectTarget.appendChild(
            new Option(audioDevice.name, audioDevice.id, false),
          );
        }
      });
      // remove device not present anymore
      options.forEach((option) => {
        if (
          !audioDevices.find((audioDevice) => option.value == audioDevice.id)
        ) {
          this.availableAudioOutputDevicesSelectTarget.removeChild(option);
        }
      });
    }
  }

  refreshInputAudioDevices(audioDevices) {
    const options = Array.from(
      this.availableAudioInputDevicesSelectTarget.options,
    );

    const lastUsedInputAudioDeviceId = localStorage.getItem(
      "acs:lastUsedInputAudioDeviceId",
    );
    const lastUsedInputAudioDevice = audioDevices.find(
      (audioDevice) => lastUsedInputAudioDeviceId == audioDevice.id,
    );

    audioDevices.forEach((audioDevice) => {
      if (audioDevice.name && !options.find((o) => o.value == audioDevice.id)) {
        this.availableAudioInputDevicesSelectTarget.appendChild(
          new Option(
            audioDevice.name,
            audioDevice.id,
            false,
            lastUsedInputAudioDevice?.id == audioDevice.id,
          ),
        );
      }
    });
    // remove device not present anymore
    options.forEach((option) => {
      if (!audioDevices.find((audioDevice) => option.value == audioDevice.id)) {
        this.availableAudioInputDevicesSelectTarget.removeChild(option);
      }
    });
  }

  refreshVideoDevices(videoDevices) {
    const options = Array.from(this.availableVideoDevicesSelectTarget.options);

    const lastUsedVideoDeviceId = localStorage.getItem(
      "acs:lastUsedVideoDeviceId",
    );
    const lastUsedVideoDevice = videoDevices.find(
      (videoDevice) => lastUsedVideoDeviceId == videoDevice.id,
    );

    videoDevices.forEach((videoDevice) => {
      if (videoDevice.name && !options.find((o) => o.value == videoDevice.id)) {
        this.availableVideoDevicesSelectTarget.appendChild(
          new Option(
            videoDevice.name,
            videoDevice.id,
            false,
            lastUsedVideoDevice?.id == videoDevice.id,
          ),
        );
      }
    });
    // remove device not present anymore
    options.forEach((option) => {
      if (!videoDevices.find((videoDevice) => option.value == videoDevice.id)) {
        this.availableVideoDevicesSelectTarget.removeChild(option);
      }
    });
  }

  setAudioOutputDevice(e) {
    if (this.hasVisio2Outlet) {
      this.visio2Outlet.setAudioOutputDevice(e.target.value);
    }
    if (this.hasPreVisioDiagnosticsOutlet) {
      this.preVisioDiagnosticsOutlet.setAudioOutputDevice(e.target.value);
    }
  }

  setAudioInputDevice(e) {
    if (this.hasVisio2Outlet) {
      this.visio2Outlet.setAudioInputDevice(e.target.value);
    }
    if (this.hasPreVisioDiagnosticsOutlet) {
      this.preVisioDiagnosticsOutlet.setAudioInputDevice(e.target.value);
    }
  }

  setVideoDevice(e) {
    if (this.hasVisio2Outlet) {
      this.visio2Outlet.setVideoDevice(e.target.value);
    }
    if (this.hasPreVisioDiagnosticsOutlet) {
      this.preVisioDiagnosticsOutlet.setVideoDevice(e.target.value);
    }
  }
}
