import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom";
import AvailabilityCalendar from "../components/availability_calendar";
// import MyCalendar from '../../javascript/packs/big_calendar'

export default class extends Controller {
  // the placeholder target can be share with other controllers
  static targets = [
    "placeholder", // html element where the react calendar should be mounted
    "wrapper", // html element used show / hide the calendar
  ];

  connect() {
    // expose this controller for external usage
    // see https://leastbad.com/stimulus-power-move for more details
    this.placeholderTarget.reactCalendar = this;

    this.mount_calendar();
  }

  disconnect() {
    this.unmount_calendar();
  }

  // PUBLIC API (can be used safely outside this controller
  refresh() {
    this.unmount_calendar();
    this.mount_calendar();
  }

  show() {
    if (this.hasWrapperTarget) {
      this.wrapperTarget.classList.remove("top");
    }
  }

  hide() {
    if (this.hasWrapperTarget) {
      this.wrapperTarget.classList.add("top");
    }
  }

  // PRIVATE API (do not use from outside this controller)
  mount_calendar() {
    const data = JSON.parse(this.placeholderTarget.dataset.coachSlots || "[]");
    // render the calendar inside the placeholder target
    const props = {
      ...data,
      readonly: this.placeholderTarget.dataset.calendarReadonly,
      timezone: this.placeholderTarget.dataset.calendarTimezone,
    };
    ReactDOM.render(
      React.createElement(AvailabilityCalendar, props),
      this.placeholderTarget,
    );

    //scroll to see time.now()
    document.getElementsByClassName("rbc-time-content")[0].scrollTop =
      (new Date().getHours() - 1) * 40;
  }

  unmount_calendar() {
    ReactDOM.unmountComponentAtNode(this.placeholderTarget);
  }
}
