import { Controller } from "stimulus";
import { patch } from "@rails/request.js";

/** Stimulus controller used to gather consents **/
export default class extends Controller {
  static values = { declined: String, given: String };

  async accept() {
    await this.submit(this.givenValue);
  }

  async decline() {
    await this.submit(this.declinedValue);
  }

  async submit(aiNotetakerConsent) {
    await patch("/users/update_ai_notetaker_consent", {
      body: { ai_notetaker_consent: aiNotetakerConsent },
    });
  }
}
