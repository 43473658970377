import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["time"];

  connect() {
    this.updateTime();
    this.interval = setInterval(() => {
      this.updateTime();
    }, 1000);
  }

  disconnect() {
    clearInterval(this.interval);
  }

  updateTime() {
    var now = new Date();
    var time_formatted = now.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    this.timeTarget.textContent = time_formatted;
  }
}
