import { post } from "@rails/request.js";
import { ApplicationController } from "stimulus-use";

export default class extends ApplicationController {
  static values = { path: String, status: String };
  static targets = ["sunny", "cloudy", "rainy", "stormy"];

  change(event) {
    const weathercastStatus = event.params.status;
    if (weathercastStatus === this.statusValue) {
      return;
    }
    post(this.pathValue, {
      body: JSON.stringify({ status: weathercastStatus }),
    });
    this.toggle(this[this.statusValue + "Target"]);
    this.toggle(this[weathercastStatus + "Target"]);
    this.statusValue = weathercastStatus;
  }

  toggle(icon) {
    if (typeof icon === "undefined") {
      return;
    }
    icon.classList.toggle("color-new-blue--900");
    icon.classList.toggle("color-new-blue--400");
  }
}
