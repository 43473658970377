import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { chatRoomDisplay } from "../utils/chat_room";

export default class extends Controller {
  static targets = ["chatRoomWrapper", "formUploadFile", "messageContent"];

  initializeChatRoom() {
    chatRoomDisplay(this.chatRoomWrapperTarget.dataset.id);
  }

  uploadFile() {
    let form = this.formUploadFileTarget;
    let action = form.getAttribute("action");
    let method = form.getAttribute("method");
    let input = this.messageContentTarget.value;

    Rails.ajax({
      type: method,
      data: new FormData(form),
      dataType: "json",
      processData: false,
      contentType: false,
      url: action,
      success: () => {
        document.querySelector("#message_content").value = input;
        document.getElementById("close-modal").click();
      },
    });
  }
}
