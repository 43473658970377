import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "wrapperPanel",
    "panel",
    "contentPanel",
    "btnPanel",
    "btnPanelMobile",
  ];

  connect() {
    if (window.innerWidth > 768 && this.hasPanelTarget) {
      this.toggleClass(this.wrapperPanelTarget, "panel--open", "add");
      this.toggleClass(this.contentPanelTargets[0], "active show", "add");
      this.toggleClass(
        this.btnPanelTargets[0],
        "active btn-panel-active",
        "add",
      );
      this.toggleClass(
        this.btnPanelMobileTargets[0],
        "active btn-panel-active",
        "add",
      );
    }
  }

  openPanel(e) {
    const wrapperPanel = this.wrapperPanelTarget;

    if (e.currentTarget.classList.contains("btn-panel-active")) {
      this.closePanel();
    } else {
      this.removeClassFromTargets(this.btnPanelTargets, "btn-panel-active");
      this.removeClassFromTargets(
        this.btnPanelMobileTargets,
        "btn-panel-active",
      );

      this.toggleClass(e.currentTarget, "btn-panel-active", "add");
      this.toggleClass(wrapperPanel, "panel--open", "add");
    }
  }

  closePanel() {
    this.removeClassFromTargets(
      this.btnPanelTargets,
      "active btn-panel-active",
    );
    this.removeClassFromTargets(
      this.btnPanelMobileTargets,
      "active btn-panel-active",
    );
    this.removeClassFromTargets(this.contentPanelTargets, "active show");
    this.toggleClass(this.wrapperPanelTarget, "panel--open", "remove");
  }

  toggleClass(element, classNames, action) {
    if (element) {
      classNames.split(" ").forEach((className) => {
        element.classList[action](className);
      });
    }
  }

  removeClassFromTargets(targets, classNames) {
    targets.forEach((target) => {
      this.toggleClass(target, classNames, "remove");
    });
  }
}
