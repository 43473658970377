import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["alertMsg", "progressBar"];

  initialize() {
    var alertMsg = this.alertMsgTarget;

    if (this.alertMsgTarget) {
      setTimeout(function () {
        alertMsg.classList.add("alert-message--hide");
      }, 7000);
    }
  }
}
