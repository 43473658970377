import { Controller } from "stimulus";
import { getVisioCallAdapter } from "@/services/visio_call/visio_call_initializer";
import { getAppSignal } from "@/utils/appsignal_utils";
import VisioDisplayManager from "@/services/visio_call/visio_display_manager";

/** Stimulus controller of visio **/
export default class extends Controller {
  static outlets = ["visio-devices-selector"];

  static values = {
    visioPlatform: String,
    roomUniqueName: String,
    acsAccessToken: String,
    testScenario: String,
    aiNotetakerBookingConfig: Object,
    scopeName: String,
    scopeInCustomVisio: String,
    userUrlToken: String,
  };
  static targets = [
    "availableAudioOutputDevicesSelect",
    "availableAudioInputDevicesSelect",
    "availableVideoDevicesSelect",
    "aiNotetakerBadgeStateNotStarted",
    "aiNotetakerBadgeStateInProgress",
    "aiNotetakerBadgeStatePaused",
    "aiNotetakerBadgeStateWaiting",
    "aiNotetakerBadgeStateRefused",
  ];

  initialize() {
    // these are used in azure_communication_service.js to enrich errors sent to newrelic
    window.scopeName = this.scopeNameValue;
    window.scopeInCustomVisio = this.scopeInCustomVisioValue || false;
    window.userUrlToken = this.userUrlTokenValue;
  }

  async connect() {
    if (import.meta.env.MODE === "test") {
      this.visioPlatformValue = "test";
    }

    this.appsignal = getAppSignal();
    this.visioDisplayManager = new VisioDisplayManager();

    this.aiNotetakerBadgeStateTarget = {
      notStarted: this.aiNotetakerBadgeStateNotStartedTarget,
      inProgress: this.aiNotetakerBadgeStateInProgressTarget,
      paused: this.aiNotetakerBadgeStatePausedTarget,
      waiting: this.aiNotetakerBadgeStateWaitingTarget,
      refused: this.aiNotetakerBadgeStateRefusedTarget,
    };
    try {
      this.visioCallAdapter = await getVisioCallAdapter(
        this.visioPlatformValue,
        this.acsAccessTokenValue,
        this.roomUniqueNameValue,
        this.testScenarioValue,
      );
      await this.visioCallAdapter.joinCall({
        initialVideoDeviceId: localStorage.getItem("acs:lastUsedVideoDeviceId"),
        initialInputAudioDeviceId: localStorage.getItem(
          "acs:lastUsedInputAudioDeviceId",
        ),
        initialOutputAudioDeviceId: localStorage.getItem(
          "acs:lastUsedOutputAudioDeviceId",
        ),
        aiNotetakerBookingConfig: this.aiNotetakerBookingConfigValue,
        aiNotetakerBadgeStateTarget: this.aiNotetakerBadgeStateTarget,
      });
      // initial list display it work only if user has already granted permissions
      // it return no devices otherwise making it a no-op
      await this.refreshOutputAudioDevices();
      await this.refreshInputAudioDevices();
      await this.refreshVideoDevices();

      this.visioDisplayManager.enableActionButtons();
    } catch (e) {
      const potentialAdblock =
        e.message === "User stack init timeout. Error: Operation timed out";
      this.visioDisplayManager.displayConnectionErrorMsg(potentialAdblock);
      this.appsignal.sendError(e, (span) => {
        span.setTags({
          roomUniqueName: this.roomUniqueNameValue,
          visioPlateform: this.visioPlatformValue,
        });
      });
    }
  }

  async refreshOutputAudioDevices() {
    const audioDevices =
      await this.visioCallAdapter.getAvailableOutputAudioDevices();
    if (this.hasVisioDevicesSelectorOutlet) {
      this.visioDevicesSelectorOutlet.refreshOutputAudioDevices(audioDevices);
    }
  }

  async refreshInputAudioDevices() {
    const audioDevices =
      await this.visioCallAdapter.getAvailableInputAudioDevices();
    if (this.hasVisioDevicesSelectorOutlet) {
      this.visioDevicesSelectorOutlet.refreshInputAudioDevices(audioDevices);
    }
  }

  async refreshVideoDevices() {
    const videoDevices = await this.visioCallAdapter.getAvailableVideoDevices();
    if (this.hasVisioDevicesSelectorOutlet) {
      this.visioDevicesSelectorOutlet.refreshVideoDevices(videoDevices);
    }
  }

  setAudioOutputDevice(audioDeviceId) {
    this.visioCallAdapter.setOutputAudioDevice(audioDeviceId);
  }

  setAudioInputDevice(audioDeviceId) {
    this.visioCallAdapter.setInputAudioDevice(audioDeviceId);
  }

  setVideoDevice(videoDeviceId) {
    this.visioCallAdapter.setVideoDevice(videoDeviceId);
  }

  disconnect() {
    this.visioCallAdapter.cleanOnDisconnect();
  }

  async hangUp() {
    await this.visioCallAdapter.leaveCall();
  }

  async toggleMute() {
    await this.visioCallAdapter.toggleMuteMicrophone();
  }

  async toggleScreenShare() {
    this.visioCallAdapter.toggleScreenShare();
  }

  async toggleBlur() {
    const isNowBlurred = await this.visioCallAdapter.toggleBlur();
    this.visioDisplayManager.toggleBlurVideoButton(isNowBlurred);
  }
}
