import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  connect() {
    this.change();
  }

  change() {
    const required = !this.checkboxTargets.some((checkbox) => checkbox.checked);
    this.checkboxTargets.forEach((input) => (input.required = required));
  }
}
