import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggleable"];

  toggle(event) {
    if (event.target.tagName == "SELECT") {
      const selectedToggleable = Array.from(event.target.selectedOptions).map(
        (option) => option.dataset.toggleToggleableParam,
      );
      this.toggleableTargets
        .filter(
          (element) =>
            element.dataset.toggleableName ==
            event.target.dataset.toggleToggleableParam,
        )
        .forEach((element) => {
          if (
            selectedToggleable.includes(element.dataset.toggleableNameValue)
          ) {
            element.classList.remove(event.target.dataset.toggleClassParam);
          } else {
            element.classList.add(event.target.dataset.toggleClassParam);
          }
        });
    } else if (event.target.type == "checkbox") {
      this.toggleableTargets
        .filter(
          (element) =>
            element.dataset.toggleableName ==
            event.target.dataset.toggleToggleableParam,
        )
        .forEach((element) => {
          element.classList.toggle(event.target.dataset.toggleClassParam);
        });
    }
  }
}
