// app/javascript/controllers/copilot_chat_controller.js

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["chat", "openButton", "closeButton", "container"];

  connect() {
    console.log("Copilot Chat Controller connected");
    this.hideChat();
  }

  hideChat() {
    this.chatTarget.classList.add("d-none");
    this.openButtonTarget.classList.remove("d-none");
    this.closeButtonTarget.classList.add("d-none");
  }

  open() {
    this.chatTarget.classList.remove("d-none");
    this.openButtonTarget.classList.add("d-none");
    this.closeButtonTarget.classList.remove("d-none");
    this.containerTarget.style.height = "400px";
    this.chatTarget.offsetHeight;
  }

  close() {
    this.hideChat();
    this.containerTarget.style.height = "auto";
    this.chatTarget.offsetHeight;
  }
}
