import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["banner", "iconNoVideo"];

  connect() {
    this.alertComponentWrapper =
      document.querySelector(".alert-component-wrapper") || null;
    this.checkMediaDevice();
  }

  checkMediaDevice() {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(() => {
        this.hideErrorMsg();
      })
      .catch((error) => {
        if (
          error.name === "NotAllowedError" ||
          error.name === "NotFoundError"
        ) {
          this.showErrorMsg();
        }
      });
  }

  showErrorMsg() {
    this.bannerTarget.classList.remove("d-none");
    this.iconNoVideoTarget.classList.remove("d-none");

    if (this.alertComponentWrapper) {
      this.alertComponentWrapper.classList.remove("d-none");
    }
  }

  hideErrorMsg() {
    this.bannerTarget.classList.add("d-none");
    this.iconNoVideoTarget.classList.add("d-none");
  }
}
