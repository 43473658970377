import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "mailContent"];

  radioValueChange(event) {
    this.contentTargets.forEach(
      (el) =>
        (el.textContent = document.querySelector(
          "[for='" + event.target.id + "']",
        ).textContent),
    );
  }

  toggle(event) {
    this.mailContentTargets.forEach((element) =>
      element.classList.add("d-none"),
    );
    document
      .getElementById("locale_" + event.target.value)
      .classList.remove("d-none");
  }
}
