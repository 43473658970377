import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "output"];
  static values = { showIf: String, outputIsRequired: Boolean };

  connect() {
    this.toggle();
  }

  toggle() {
    let showOutput =
      this.inputTarget.type === "checkbox"
        ? this.inputTarget.checked.toString() === this.showIfValue
        : this.inputTarget.value === this.showIfValue;

    // Hide or show the output
    this.outputTarget.hidden = !showOutput;

    // Make it required or optional if necessary
    if (showOutput && this.outputIsRequiredValue) {
      this.outputTarget
        .querySelector("input")
        .setAttribute("required", "required");
    } else {
      this.outputTarget.querySelector("input").removeAttribute("required");
    }
  }
}
