import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["firstName", "lastName", "locale"];

  autocomplete(event) {
    let info = event.detail.textValue.split("|");

    this.firstNameTarget.value = info[0];
    this.lastNameTarget.value = info[1];
    this.localeTarget.value = info[2];

    this.firstNameTarget.readOnly = true;
    this.lastNameTarget.readOnly = true;
    this.localeTarget.readOnly = true;
  }
}
