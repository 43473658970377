import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["loader", "counter"];
  interval = null;

  static values = {
    autoLoad: Boolean,
  };

  connect() {
    this.counterTarget.textContent = "30";

    if (this.autoLoadValue) {
      this.startLoader();
    }
  }

  startLoader() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }

    let seconds = 30;
    this.interval = setInterval(() => {
      seconds--;
      this.counterTarget.textContent = seconds;
      if (seconds <= 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  }
}
