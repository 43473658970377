import { Controller } from "stimulus";

/**
 * Component for a Tab using the _tab.scss style.
 * Stop using this Tab; use the tab from Bootstrap 5: https://getbootstrap.com/docs/5.0/components/navs-tabs/
 *
 * @param {Number} index - The index of the default tab content displayed.
 * @param {String} contentVisible - This determines whether the content is hidden or not. If set to 'hidden', the content is hidden by default.
 */
export default class extends Controller {
  static targets = ["tab", "panel"];

  static values = {
    index: Number,
    contentVisible: String,
  };

  connect() {
    this.index = this.indexValue;
    this.contentVisibleValue == "hidden" ? this.hideTab() : this.showTab();
  }

  change(event) {
    this.index = this.tabTargets.indexOf(event.currentTarget);
  }

  showTab() {
    if (this.tabTargets[this.index]) {
      this.tabTargets.forEach((tab, index) => {
        const panel = this.panelTargets[index];
        tab?.classList.toggle("-is-active", index == this.index);
        panel?.classList.toggle("-is-hidden", index != this.index);
      });
    }
  }

  hideTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index];
      panel.classList.add("-is-hidden");
    });
  }

  get index() {
    return parseInt(this.data.get("index") || 0);
  }

  set index(value) {
    this.data.set("index", value);
    this.showTab();
  }
}
