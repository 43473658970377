import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "form",
    "deviseSection",
    "emailField",
    "phoneField",
    "passwordFieldRequired",
    "passwordConfirmationFieldRequired",
    "submitButton",
    "ssoForm",
  ];

  static values = {
    deviseLabel: String,
    ssoLabel: String,
    minifiedLabel: String,
    form: String, // indicates the type of form (login  or register)
    mode: String, // indicates the mode of the form (minified - aka only email, devise or sso)
  };

  connect() {
    if (!this.hasModeValue) {
      this.modeValue = "minified"; // default mode
    }

    if (this.modeValue === "minified") {
      this.transitionToMinified();
    }

    if (this.modeValue === "devise") {
      this.transitionToDevise();
    }
  }

  transitionToMinified() {
    this.emailFieldTarget.classList.remove("-read-only-clickable");

    this.deviseSectionTargets.forEach((section) => {
      section.hidden = true;
    });

    if (this.hasMinifiedLabelValue) {
      this.submitButtonTarget.value = this.minifiedLabelValue;
    }
  }

  togglePassword() {
    if (this.modeValue !== "minified") {
      this.modeValue = "minified";
      this.transitionToMinified();
    }
  }

  transitionToDevise() {
    this.emailFieldTarget.classList.add("-read-only-clickable");

    if (this.hasPhoneFieldTarget) {
      this.phoneFieldTarget.required = true;
    }

    if (this.hasPasswordFieldRequiredTarget) {
      this.passwordFieldRequiredTarget.required = true;
    }
    if (this.hasPasswordConfirmationFieldRequiredTarget) {
      this.passwordConfirmationFieldRequiredTarget.required = true;
    }

    this.deviseSectionTargets.forEach((section) => {
      section.hidden = false;
    });

    if (this.hasDeviseLabelValue) {
      this.submitButtonTarget.value = this.deviseLabelValue;
    }
  }

  async submit(event) {
    if (this.modeValue === "minified") {
      // do not submit the form
      event.preventDefault();

      await this.checkSso();
    }
  }
  async checkSso() {
    // check the email on the server in order to decide if switch to devise mode or if we redirect to sso
    const url = `/sso_checks/${this.formValue}`;
    const response = await get(url, {
      query: { email: this.emailFieldTarget.value },
    });

    if (response.ok) {
      const data = await response.json;

      if (data.use_sso) {
        this.modeValue = "sso";
        this.redirectToSso(data);
      } else {
        this.modeValue = "devise";
        this.transitionToDevise();
      }
    }
  }

  redirectToSso(data) {
    // when you try to login after a logout with reloading the signin page
    // we got this error "can't verify CSRF token authenticity"
    Rails.refreshCSRFTokens();
    this.submitButtonTarget.value = this.ssoLabelValue;
    this.submitButtonTarget.disabled = true;

    this.ssoFormTarget.appendChild(
      this.buildHiddenField("login_hint", data.email),
    );
    this.ssoFormTarget.appendChild(
      this.buildHiddenField("organisation", data.organisation),
    );

    this.ssoFormTarget.action = data.authenticate_url;
    this.ssoFormTarget.submit();
  }

  buildHiddenField(name, value) {
    let input = document.createElement("input");

    input.setAttribute("type", "hidden");
    input.setAttribute("name", name);
    input.setAttribute("value", value);

    return input;
  }
}
