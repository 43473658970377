import { Controller } from "stimulus";
import intlTelInput from "intl-tel-input";

export default class extends Controller {
  static targets = ["phone_input", "ai_notetaker_consent", "submit"];

  connect() {
    this.formatPhoneInput();
  }

  formatPhoneInput() {
    const url = new URLSearchParams(window.location.href);

    intlTelInput(this.phone_inputTarget, {
      formatOnInit: true,
      separateDialCode: true,
      hiddenInput: "phone",
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.12/js/utils.js",
      initialCountry: url.get("prefix"),
      preferredCountries: ["fr", "gb", "de", "ch", "us", "it", "es"],
    });
  }
  onSubmit(e) {
    let modal = document.getElementById("confirm-decline-ai-notetaker-consent");

    if (
      !this.ai_notetaker_consentTarget.checked &&
      this.ai_notetaker_consentTarget.dataset.initialValue == "given" &&
      !modal.classList.contains("show")
    ) {
      e.preventDefault();
      this.showModal(modal);
    }
  }

  showModal(modal) {
    document.getElementById("backdrop").style.display = "block";
    document.querySelector("body").classList.add("modal-open");
    modal.style.display = "block";
    modal.classList.add("show");
  }
}
