import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["inputs"];

  addRequired(e) {
    this.inputsTargets.forEach((input) => {
      input.required = false;
    });
    const inputId = e.target.getAttribute("data-input-required");
    document.querySelector(inputId).required = true;
  }
}
