import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];
  static values = { dealId: String };

  connect() {
    if (this.dealIdValue) {
      this.build_hubspot_url(this.dealIdValue);
    }
  }

  display_hubspot_url(event) {
    const dealId = event.target.value;

    this.build_hubspot_url(dealId);
  }

  build_hubspot_url(dealId) {
    const hubspot_url = dealId
      ? "https://app.hubspot.com/contacts/3783746/deal/" + dealId
      : "";
    this.outputTarget.textContent = hubspot_url;
    this.outputTarget.setAttribute("href", hubspot_url);

    this.outputTarget.classList.toggle("d-none", !hubspot_url);
  }
}
