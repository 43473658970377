
export default class VisioDisplayManager {
    constructor() {
        this.initializeDOMElements();
    }

    initializeDOMElements() {
        this.local_video_container = document.getElementById('localVideoContainer');
        this.with_screensharing_container = document.getElementById('withScrensharingContainer');
        this.remote_big_stream_container = document.getElementById('remoteBigStreamContainer');
        this.screen_share_button = document.getElementById('toggleScreenShareButton');
        this.mute_microphone_button = document.getElementById('toggleMuteButton');
        this.blur_video_button = document.getElementById('toggleBlurButton');
        this.msg_waiting_for_other_user = document.getElementById('waitingForOtherUser')
        this.connection_in_progress_msg = document.getElementById('connectionInProgressMsg');
        this.connection_please_retry_msg = document.getElementById('connectionPleaseRetry');
        this.connection_disable_adblock = document.getElementById('connectionAdblockDetected');
        this.error_message = document.querySelector('.visio__error-msg');
    }

    displayStream(streamType, isLocal, streamHtml, useSmallContainer = false) {
        if (streamType === 'video') {
            if (isLocal) {
                this.local_video_container.replaceChildren(streamHtml)
            } else {
                if (useSmallContainer) {
                    this.with_screensharing_container.replaceChildren(streamHtml)
                } else {
                    this.remote_big_stream_container.replaceChildren(streamHtml)
                }
            }
        }
        else if (streamType === 'screensharing') {
            if (isLocal) {
                this.with_screensharing_container.replaceChildren(streamHtml)
            } else {
                this.remote_big_stream_container.replaceChildren(streamHtml);
            }
        }
    }

    removeStream(streamType, isLocal, useSmallContainer = false) {
        this.displayStream(streamType, isLocal, '', useSmallContainer)
    }

    enableActionButtons() {
        this.mute_microphone_button.disabled = false;
        this.screen_share_button.disabled = false;
        this.connection_in_progress_msg.classList.add('d-none');
        if (this.blur_video_button) {
          this.blur_video_button.disabled = false;
        }
    }

    toggleScreenShareButton(toActive) {
        if(toActive) {
            this.screen_share_button.classList.add("icon--active")
        } else {
            this.screen_share_button.classList.remove("icon--active")
        }
    }

    toggleBlurVideoButton(toActive) {
        if (this.blur_video_button) {
          if(toActive) {
              this.blur_video_button.classList.add("icon--active")
          } else {
              this.blur_video_button.classList.remove("icon--active")
          }
        }
    }

    toggleMuteMicrophoneButtonState() {
        this.mute_microphone_button.classList.toggle("icon--inactive")
    }

    showWaitingForOtherUserMsg() {
        if (this.connection_please_retry_msg.classList.contains('d-none')) {
            this.msg_waiting_for_other_user.classList.remove("d-none")
        }
    }

    hideWaitingForOtherUserMsg() {
        this.msg_waiting_for_other_user.classList.add("d-none")
    }

    displayConnectionErrorMsg(potentialAdblock) {
        this.connection_in_progress_msg.classList.add('d-none');
        this.error_message.classList.remove('d-none');
        if(potentialAdblock) {
            this.connection_disable_adblock.classList.remove('d-none');
        } else {
            this.connection_please_retry_msg.classList.remove('d-none');
        }
    }
}
