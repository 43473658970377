import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    this.buttonTarget.addEventListener("click", (event) => {
      this.handleButtonClick(event);
    });
  }

  handleButtonClick(event) {
    const target = event.target;
    const feature = target.getAttribute("data-ga-feature");
    const page = target.getAttribute("data-ga-page");
    const button = target.getAttribute("data-ga-button");

    this.sendDataToDataLayer(feature, page, button);
  }

  sendDataToDataLayer(feature, page, button) {
    /* eslint-disable */
    window.dataLayer = window.dataLayer || [];

    dataLayer.push({
      event: "elementClick",
      feature: feature,
      page: page,
      button: button,
    });
    /* eslint-enable */
  }
}
