import Appsignal from '@appsignal/javascript'

const appsignal = new Appsignal({
  key: import.meta.env.VITE_APPSIGNAL_PUSH_FRONT_API_KEY,
  revision: import.meta.env.VITE_APP_REVISION
});

// Use AppSignal hooks to send additional metadata to errors.
// see https://docs.appsignal.com/front-end/hooks.html
appsignal.addDecorator((span) => {
  return span.setTags({
    path: window?.location?.pathname
  });
});


export function getAppSignal() {
  return appsignal
}
