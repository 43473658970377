import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["form"];

  change(event) {
    if (event.target.value) {
      // avoid error with verify_csrf token. Without this it fail 1/2 time
      Rails.refreshCSRFTokens();
      this.formTarget.setAttribute(
        "action",
        `/admins/${event.target.value}/impersonate`,
      );
      this.formTarget.submit();
    }
  }
}
