import { Controller } from "stimulus";
import { post, put } from "@rails/request.js";
import { Device } from "twilio-client";
import { getAppSignal } from "@/utils/appsignal_utils";
import { makeFakeDevice } from "@/utils/phone_call_fake_device";

export default class extends Controller {
  static values = {
    recipientId: String,
    recipientPhoneNumber: String,
    simundiaPhoneId: String,
    simundiaPhoneNumber: String,
    bookingId: String,
    callerId: String,
    token: String,
    sid: String,
  };
  static targets = ["callButton", "hangUpButton", "microphoneButton"];

  connect() {
    this.initDeviceCallback();

    // set visual button
    if (this.getDevice().activeConnection()) {
      this.setButtonsDuringACall();
    }
  }

  initDeviceCallback() {
    // Trigger when the Device is ready to place call
    this.getDevice().on("ready", () => {
      this.phoneCall();
    });

    // When any device error occurs during the call
    this.getDevice().on("error", (error) => {
      if (error.code !== 31205) {
        this.hangUp();
        getAppSignal().sendError(error);
      }
    });

    // When an incoming connection is canceled by the caller before it is accepted by Twilio
    this.getDevice().on("cancel", () => {
      this.hangUp();
    });

    this.getDevice().on("connect", (connection) => {
      // get sid of call to update phone call
      this.sidValue = connection.parameters.CallSid;
      this.setButtonsDuringACall();
    });

    this.getDevice().on("disconnect", () => {
      this.hangUp();

      this.element.classList.remove("phone-call-in-progress");
      this.element.classList.add("phone-call-finish");
      this.microphoneButtonTarget.classList.remove("microphone--muted");
    });
  }

  setButtonsDuringACall() {
    this.hangUpButtonTarget.disabled = false;
    this.hangUpButtonTarget.classList.remove("d-none");

    this.callButtonTarget.disabled = true;
    this.callButtonTarget.classList.add("d-none");
  }

  triggerMuted(event) {
    event.currentTarget.classList.toggle("microphone--muted");

    const activeConnection = this.getDevice().activeConnection;
    if (activeConnection()) {
      activeConnection().mute(!activeConnection().isMuted());
    }
  }

  hangUp() {
    this.getDevice().destroy();

    this.hangUpButtonTarget.disabled = true;
    this.hangUpButtonTarget.classList.add("d-none");

    put(`/phone_calls/${this.tokenValue}.json`, {
      contentType: "application/json",
      body: this.phonecallParams(),
    }).then(() => {
      this.callButtonTarget.classList.remove("d-none");
      this.callButtonTarget.disabled = false;
    });
  }

  phonecallParams() {
    const params = {
      phone_call: {
        reason: "",
        recipient_id: this.recipientIdValue,
        action_required_id: "",
        phone_number: this.recipientPhoneNumberValue,
        booking_id: this.bookingIdValue,
        caller_id: this.callerIdValue,
        simundia_phone_id: this.simundiaPhoneIdValue,
        token: this.tokenValue,
        sid: this.sidValue,
      },
    };

    return params;
  }

  phoneCall() {
    const params = {
      To: this.recipientPhoneNumberValue,
      From: this.simundiaPhoneNumberValue,
      Caller: "Simundia",
    };

    post("/phone_calls.json", {
      body: JSON.stringify(this.phonecallParams()),
      responseKind: "json",
    })
      .then((response) => response.json)
      .then((element) => {
        this.tokenValue = element.token;
        this.getDevice().connect(params);
      });
  }

  startCallButton() {
    this.element.classList.remove("phone-call-init");
    this.element.classList.remove("phone-call-finish");
    this.element.classList.add("phone-call-in-progress");

    this.generateToken();
  }

  generateToken() {
    post("/generate_token", { responseKind: "json" })
      .then((response) => response.json)
      .then((element) => {
        this.simundiaPhoneIdValue = element.simundia_phone.id;
        this.simundiaPhoneNumberValue = element.simundia_phone.number;
        this.getDevice().setup(element.token);
      });
  }

  getDevice() {
    if (this.device === undefined) {
      this.device = this.makeDevice();
    }

    return this.device;
  }

  makeDevice() {
    if (
      import.meta.env.MODE === "development" ||
      import.meta.env.MODE === "test"
    ) {
      const fakeDevice = makeFakeDevice();
      // This makes the fake device accessible to capybara during tests.
      window.getDevice = function () {
        return fakeDevice;
      };
      return fakeDevice;
    }

    return Device;
  }
}
