import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "submit", "input"];

  selectValue(e) {
    this.submitTarget.disabled = false;
    this.inputTarget.value = e.currentTarget.dataset.value;
  }

  submit() {
    this.inputTarget.disabled = true;
    this.formTarget.submit();
  }

  clearForm() {
    this.formTarget.reset();
    this.submitTarget.disabled = true;
    this.inputTarget.value = "";
    this.formTarget.scrollIntoView();
  }
}
