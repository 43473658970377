import { Controller } from "stimulus";

export default class extends Controller {
  updateMailerField(event) {
    const ccManager = document.querySelector(".boolean");
    const mailerField = document.querySelector(
      `.mailing_${event.target.dataset.templateIdValue}`,
    );

    if (event.target.value === "kss") {
      mailerField.classList.add("d-none");
      mailerField.children[1].value = " ";
      ccManager.classList.add("d-none");
      ccManager.getElementsByTagName("input")[1].checked = false;
    } else {
      ccManager.classList.remove("d-none");
      mailerField.classList.remove("d-none");
      mailerField.children[1].value = null;
    }
  }
}
