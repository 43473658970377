import { Controller } from "stimulus";
import { useVisibility } from "stimulus-use";
export default class extends Controller {
  connect() {
    this.initialPage = document.location;
    useVisibility(this);
  }

  waitTillVisible(event) {
    this.formUrl = event.params.newFormUrl;
    this.bookingEndTime = event.params.bookingEndTime;
  }
  visible() {
    if (
      this.isWithinTimeFrame() &&
      window.location == this.initialPage &&
      document.visibilityState == "visible" &&
      this.formUrl
    ) {
      Turbo.visit(this.formUrl);
    }
  }
  isWithinTimeFrame() {
    const bookingEndTime = new Date(this.bookingEndTime);
    const now = new Date();
    const timeDifference = (bookingEndTime - now) / (1000 * 60);
    return timeDifference >= -3 && timeDifference <= 3; // 3 minutes before end_time untill 3 minutes after
  }
}
