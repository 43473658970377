import { Controller } from "stimulus";
import Chart from "chart.js/auto";

export default class extends Controller {
  static values = { counters: Object };

  connect() {
    const data = {
      datasets: [
        {
          data: this.countersValue.values,
          backgroundColor: this.countersValue.colors,
        },
      ],
      labels: this.countersValue.labels,
    };
    this.chart = new Chart(this.element.getContext("2d"), {
      type: "doughnut",
      data: data,
      options: { cutout: "70%", aspectRatio: 2 },
    });
  }

  disconnect() {
    if (this.chart) this.chart.destroy();
  }
}
