import { Controller } from "stimulus";
import Chart from "chart.js/auto";

export default class extends Controller {
  static values = { data: Array };

  static locale = () => document.body.dataset.locale;

  connect() {
    const locale = document.body.dataset.locale;
    const data = {
      datasets: [
        {
          data: this.dataValue,
          backgroundColor: ["#87D4BD", "#ED6E6E"],
        },
      ],
      labels: [
        locale == "fr"
          ? `Oui (${this.dataValue[0]})`
          : `Yes (${this.dataValue[0]})`,
        locale == "fr"
          ? `Non (${this.dataValue[1]})`
          : `No (${this.dataValue[1]})`,
      ],
    };
    this.chart = new Chart(this.element.getContext("2d"), {
      type: "doughnut",
      data: data,
      options: { cutout: "70%", aspectRatio: 2, responsive: false },
    });
  }

  disconnect() {
    if (this.chart) this.chart.destroy();
  }
}
