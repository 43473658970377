import { Controller } from "stimulus";

export default class extends Controller {
  static values = { config: String };

  initialize() {
    // For every page load, see if Front JS is loaded. If not, load it.
    // If so, reinitialize it to work with the reloaded page.
    if (this.configValue) {
      this.config = JSON.parse(this.configValue);
      if (this.frontChatIsLoaded()) {
        this.initFrontChat();
      } else {
        this.loadFrontChat();
      }
    }
  }

  openChat() {
    window.FrontChat("show");
  }

  frontChatIsLoaded() {
    return !!window.Front;
  }

  initFrontChat() {
    window.FrontChat("init", {
      chatId: this.config["chat_id"],
      useDefaultLauncher: true,
      email: this.config["user_email"],
      userHash: this.config["user_hash"],
      name: this.config["user_name"],
      customFields: {
        Scope: this.config["user_scope"],
      },
    });
  }

  loadFrontChat() {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.onload = () => this.initFrontChat();
    script.src = "https://chat-assets.frontapp.com/v1/chat.bundle.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }
}
