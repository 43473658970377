import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import Swal from "sweetalert2";

export default class extends Controller {
  static targets = [
    "expandableList",
    "category",
    "situation",
    "softskill",
    "confirmSearchCoach",
    "commentSearchCoach",
    "language",
    "carousel",
    "calendar",
    "selectedCoach",
    "coachingSituationReminder",
  ];
  static values = { expert: String, relationship: String };

  toggleExpand(e) {
    const coachCard = e.target.closest(".coach-card");
    coachCard.classList.toggle("coach-card--expand");
  }

  selectCoach(event) {
    if (event.target.tagName !== "BUTTON") {
      let selectExpert = event.currentTarget.dataset.setExpertValue;
      let from =
        event.target.getElementsByClassName("coach-card")[0] ||
        event.target.closest(".coach-card");
      let check = from.getElementsByClassName("coach-card__button")[0];

      // unselect the previously selected coach (if any)
      document
        .querySelectorAll(".coach-card--selected, .coach-card__button--checked")
        .forEach((e) => {
          e.classList.remove("coach-card__button--checked");
          e.classList.remove("coach-card--selected");
        });
      this.selectedCoachTarget.value = "";

      // select the new coach
      from.classList.add("coach-card--selected");
      check.classList.add("coach-card__button--checked");
      this.selectedCoachTarget.value = selectExpert;
    }
  }

  triggerMatch() {
    Rails.ajax({
      type: "GET",
      data: `relationship[custom_info][language]=${this.languageTarget.value}&relationship[situation]=${this.situationTarget.value}&relationship[softskill]=${this.softskillTarget.value}`,
      url: `/relationships/${this.relationshipValue}/retrigger_match.html`,
      success: (element) => {
        // refresh coach carousel
        if (this.hasCarouselTarget) {
          this.carouselTarget.innerHTML = element.body.firstChild.innerHTML;
          this.carouselTarget.dataset.coachSlots =
            element.body.firstChild.dataset.coachSlots;
        }

        // refresh calendar
        if (this.hasCalendarTarget) {
          this.calendarTarget.dataset.coachSlots =
            element.body.firstChild.dataset.coachSlots;
          this.calendarTarget.reactCalendar.refresh();
        }

        // reset previously selected coach (if any)
        if (this.hasSelectedCoachTarget) {
          this.selectedCoachTarget.value = "";
        }
      },
    });
  }

  changeCommentCoach() {
    if (this.commentSearchCoachTarget.value.length > 0) {
      this.confirmSearchCoachTarget.disabled = false;
    } else {
      this.confirmSearchCoachTarget.disabled = true;
    }
  }

  confirmOtherCoach() {
    this.triggerMatch();
  }

  errorUpdateRelationship(event) {
    // error that pop when admin has forgotten to select a coach in the set_expert page
    const {
      detail: { success },
    } = event;
    if (!success) {
      event.detail.fetchResponse.response.text().then((data) => {
        let html = "<div> <span>An error occurred</span></div>";

        html += '<details data-controller="copy-to-clipboard">';
        html += "<summary>Show error message </summary>";
        html +=
          '<button class="btn btn-black" data-id-value="message-content" data-action="click->copy-to-clipboard#copy">Copy</button>';
        html +=
          '<p id="message-content" data-copy-to-clipboard-target="source">';
        html += JSON.parse(data).errors || data;
        html += "</p> </details>";
        Swal.fire({
          html: html,
          icon: "error",
          showConfirmButton: false,
          showCloseButton: true,
        });
      });
    }
  }

  setCoachingSituationReminder(event) {
    const situationSelect = event.target;
    const newSituationText =
      situationSelect.options[situationSelect.selectedIndex].text;
    if (newSituationText) {
      this.coachingSituationReminderTarget.innerHTML = newSituationText;
    }
  }
}
