import { Controller } from "stimulus";
import { getDiagnosticsAdapter } from "@/services/diagnostics/get_diagnostics_adapter";
import { interpolation } from "@/utils/dictionaries_utils";

export default class extends Controller {
  static outlets = ["visio-devices-selector"];
  static targets = [
    "connectionButton",
    "connectionQualityReport",
    "progressBar",
    "videoTest",
    "audioTestButton",
    "loader",
  ];
  static values = {
    urlTwilioVideoToken: String,
    urlStunTurnToken: String,
    visioPlatform: String,
    acsAccessToken: String,
    urlDisplayMessageConnectionReport: String,
    dictionaries: Object,
  };

  GOOD_QUALITY_TRANSLATIONS = ["excellent", "good"];
  CONNECTION_TEST_THRESHOLD = 3;

  connect() {
    this.initializeConfiguration();
    this.observeStreamElement();
  }

  disconnect() {
    this.diagnosticsAdapter.stopVideoStream(this.videoTestTarget);
    this.diagnosticsAdapter.cleanOnDisconnect();
  }

  initializeConfiguration() {
    this.connectionTestCount = 0;

    let visioPlatform = this.visioPlatformValue;

    if (import.meta.env.MODE === "test") {
      visioPlatform = "test";
    }

    this.diagnosticsAdapter = getDiagnosticsAdapter(
      visioPlatform || "twilio",
      this.urlTwilioVideoTokenValue,
      this.urlStunTurnTokenValue,
      this.acsAccessTokenValue,
    );
  }

  setAudioOutputDevice(audioDeviceId) {
    this.diagnosticsAdapter.setOutputAudioDevice(audioDeviceId);
  }

  setAudioInputDevice(audioDeviceId) {
    this.stopMicrophoneTest();
    this.diagnosticsAdapter.setInputAudioDevice(audioDeviceId);
    this.runMicrophoneTest();
  }

  setVideoDevice(videoDeviceId) {
    this.diagnosticsAdapter.setVideoDevice(videoDeviceId);
  }

  async refreshOutputAudioDevices() {
    const audioDevices =
      await this.diagnosticsAdapter.getAvailableOutputAudioDevices();
    if (this.hasVisioDevicesSelectorOutlet) {
      this.visioDevicesSelectorOutlet.refreshOutputAudioDevices(audioDevices);
    }
  }

  async refreshInputAudioDevices() {
    const audioDevices =
      await this.diagnosticsAdapter.getAvailableInputAudioDevices();
    if (this.hasVisioDevicesSelectorOutlet) {
      this.visioDevicesSelectorOutlet.refreshInputAudioDevices(audioDevices);
    }
  }

  async refreshVideoDevices() {
    const videoDevices =
      await this.diagnosticsAdapter.getAvailableVideoDevices();
    if (this.hasVisioDevicesSelectorOutlet) {
      this.visioDevicesSelectorOutlet.refreshVideoDevices(videoDevices);
    }
  }

  async onTurboLoad() {
    await this.diagnosticsAdapter.setInputAudioDevice(
      localStorage.getItem("acs:lastUsedInputAudioDeviceId"),
    );
    await this.diagnosticsAdapter.setOutputAudioDevice(
      localStorage.getItem("acs:lastUsedOutputAudioDeviceId"),
    );
    // please always call this before starting test and await for it, do not move until it is done
    // calling it without await multiple time will make ACS raise an error that the token is already use.
    await this.diagnosticsAdapter.setupVisioProvider();
    this.runDiagnosticsTests();
  }

  runDiagnosticsTests() {
    this.runMicrophoneTest();
    this.runVideoTest();
    this.triggerConnectionTest();
  }

  runMicrophoneTest() {
    // get the event emitter from the adapter
    const microphoneTest = this.diagnosticsAdapter.startMicrophoneTest();
    // attach a callback to the emitter and animate UI on each event
    microphoneTest.on("volume", (value) => {
      window.requestAnimationFrame(() => {
        this.progressBarTarget.style.width = `${Math.max(0, this.diagnosticsAdapter.getAudioLevelPercentage(value))}%`;
      });
    });
  }

  stopMicrophoneTest() {
    this.diagnosticsAdapter.stopMicrophoneTest();
  }

  runVideoTest() {
    this.diagnosticsAdapter.startVideoTest(this.videoTestTarget);
  }

  triggerConnectionTest() {
    this.connectionTestCount++;
    this.connectionQualityReportTarget.classList.remove("color-red");

    const bandwidthTestPromise = this.diagnosticsAdapter.startBandwidthTest();

    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Timeout occurred"));
      }, 45000);
    });

    Promise.race([bandwidthTestPromise, timeoutPromise])
      .then((qualityScore) => {
        this.displayConnectionMessage(qualityScore);
      })
      .catch(() => {
        this.connectionQualityReportTarget.innerHTML =
          this.dictionariesValue.connection.error;
        this.connectionQualityReportTarget.classList.add("color-red");
        this.connectionButtonTarget.classList.remove("d-none");
        this.loaderTarget.classList.remove("-loading");
      });
  }

  relaunchConnectTest() {
    if (this.connectionTestCount === this.CONNECTION_TEST_THRESHOLD) {
      this.connectionButtonTarget.disabled = true;
      this.connectionQualityReportTarget.innerHTML =
        this.dictionariesValue.connection.too_many_tests;
      return;
    }
    this.diagnosticsAdapter.cleanOnDisconnect();
    this.connectionQualityReportTarget.innerHTML =
      this.dictionariesValue.connection.in_progress;
    this.connectionButtonTarget.classList.add("d-none");
    this.loaderTarget.classList.add("-loading");
    this.triggerConnectionTest();
  }

  displayConnectionMessage(qualityScore) {
    const quality =
      typeof qualityScore === "number"
        ? this.diagnosticsAdapter.connectionQuality(qualityScore)
        : qualityScore;
    this.connectionQualityReportTarget.innerHTML =
      this.translateConnectionQuality(quality);
    this.connectionButtonTarget.classList.remove("d-none");
    this.loaderTarget.classList.remove("-loading");
  }

  translateConnectionQuality(quality) {
    const qualityTranslation =
      this.dictionariesValue.connection[quality] || "Unknown";
    if (this.GOOD_QUALITY_TRANSLATIONS.includes(quality)) {
      return interpolation(
        this.dictionariesValue.connection.excellent_good_html,
        { quality_score: qualityTranslation },
      );
    } else {
      return interpolation(
        this.dictionariesValue.connection.suboptimal_poor_html,
        { quality_score: qualityTranslation },
      );
    }
  }

  startAndStopAudioOutputTest() {
    if (this.testAudioOutputDevice) {
      this.stopAudioOutputTest();
    } else {
      this.startAudioOutputTest();
    }
  }

  startAudioOutputTest() {
    this.audioTestButtonTarget.innerHTML =
      this.dictionariesValue.speaker.test_in_progress_button;
    this.testAudioOutputDevice = this.diagnosticsAdapter.startAudioOutputTest();
    this.testAudioOutputDevice.on("end", () => {
      this.audioOutputTestEnd();
    });
  }

  stopAudioOutputTest() {
    this.diagnosticsAdapter.stopAudioOutputTest(this.testAudioOutputDevice);
    this.audioOutputTestEnd();
  }

  audioOutputTestEnd() {
    this.audioTestButtonTarget.innerHTML =
      this.dictionariesValue.speaker.test_button;
    this.testAudioOutputDevice = null;
  }

  observeStreamElement() {
    // observer used to hide Video DOM element added by ACS once it arrives
    this.streamObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          mutation.addedNodes.forEach((node) => {
            if (node.id === "_hiddenSelfRemoteStream") {
              this.applyDisplayNone(node);
            }
          });
        }
        if (
          mutation.type === "attributes" &&
          mutation.target.id === "_hiddenSelfRemoteStream"
        ) {
          this.applyDisplayNone(mutation.target);
        }
      });
    });

    this.streamObserver.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
    });
  }

  applyDisplayNone(element) {
    if (element && element.style.display !== "none") {
      element.style.display = "none";
    }
  }
}
