import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["star", "inputWrapper"];

  static values = {
    value: Number,
  };

  connect() {
    this.highlightStars(this.valueValue);
  }

  highlightStars(lastStarValue) {
    this.starTargets.forEach((star, index) => {
      if (index <= lastStarValue - 1) {
        star.classList.add("highlight");
      } else {
        star.classList.remove("highlight");
      }
    });
  }

  toggleSelected(e) {
    const input =
      document.getElementById(e.params.input) ||
      this.inputWrapperTarget.querySelector("input");

    if (e.params.value == input.value) {
      input.value = null;
      this.highlightStars();
    } else {
      input.value = e.params.value;
      this.highlightStars(e.params.value);
    }
  }
}
