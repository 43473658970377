import { Controller } from "stimulus";
/**
 Change user manager controller to display inputs when new manager
 */
export default class extends Controller {
  static targets = ["coacheeInputs"];

  onManagerAdded() {
    // show hidden first name, last name and locale input when adding a new manager
    this.coacheeInputsTarget.classList.remove("d-none");
  }
}
