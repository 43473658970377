import AcsVisioHelper from './acs_visio_call_utils'
import AcsVisioRecordingHelper from './acs_visio_recording_utils'
import { LocalVideoStream, LocalAudioStream, Features } from "@azure/communication-calling"
import { initializeAcsComponents } from '../../services/azure_communication_service'
import { patch } from "@rails/request.js";

export default class AcsVisioCallAdapter {
  constructor(acsAccessToken, roomId) {
    this.acsAccessToken = acsAccessToken
    this.roomId = roomId
  }

  async getAvailableOutputAudioDevices() {
    const deviceManager = await this.deviceManager()

    if (deviceManager.isSpeakerSelectionAvailable) {
      return deviceManager.getSpeakers()
    }
    else {
      return Promise.resolve([])
    }
  }

  async getAvailableInputAudioDevices() {
    const deviceManager = await this.deviceManager()

    try {
      return deviceManager.getMicrophones()
    } catch(_) {
      return Promise.resolve([])
    }
  }

  async getAvailableVideoDevices() {
    const deviceManager = await this.deviceManager()

    try {
      return deviceManager.getCameras()
    } catch(_) {
      return Promise.resolve([])
    }
  }

  async setOutputAudioDevice(audioDeviceId) {
    const deviceManager = await this.deviceManager()

    this.outputAudioDevice = (await deviceManager.getSpeakers()).find(
      (audioDevice) => audioDevice.id === audioDeviceId
    )
    if (this.outputAudioDevice) {
      await deviceManager.selectSpeaker(this.outputAudioDevice)
      localStorage.setItem('acs:lastUsedOutputAudioDeviceId', audioDeviceId)
    }
  }

  async setInputAudioDevice(audioDeviceId) {
    const deviceManager = await this.deviceManager()

    this.inputAudioDevice = (await deviceManager.getMicrophones()).find(
      (audioDevice) => audioDevice.id === audioDeviceId
    )
    await deviceManager.selectMicrophone(this.inputAudioDevice)
    localStorage.setItem('acs:lastUsedInputAudioDeviceId', audioDeviceId)
  }

  async setVideoDevice(videoDeviceId) {
    const deviceManager = await this.deviceManager()

    this.videoDevice = (await deviceManager.getCameras()).find(
      (videoDevice) => videoDevice.id === videoDeviceId
    )
    await this.localVideoStream.switchSource(this.videoDevice);
    localStorage.setItem('acs:lastUsedVideoDeviceId', videoDeviceId)
  }

  async deviceManager() {
    if (!this._deviceManager) {
      const { callClient, callAgent } = await initializeAcsComponents(this.acsAccessToken)
      this._callAgent = callAgent
      this._callClient = callClient
      this._deviceManager = await this._callClient.getDeviceManager()
      await this._deviceManager.askDevicePermission({ audio: true, video: true })
      this._deviceManager.on('audioDevicesUpdated', (e) => {
        const event = new CustomEvent("visioCall:audioDevicesChange", { detail: e})
        window.dispatchEvent(event)
      } )
      this._deviceManager.on('videoDevicesUpdated', (e) => {
        const event = new CustomEvent("visioCall:videoDevicesChange", { detail: e})
        window.dispatchEvent(event)
      } )
    }
    return this._deviceManager
  }


  async joinCall({initialVideoDeviceId, initialInputAudioDeviceId, initialOutputAudioDeviceId, aiNotetakerBookingConfig, aiNotetakerBadgeStateTarget } = { aiNotetakerBookingConfig: {}, aiNotetakerBadgeStateTarget: {} }) {
    const deviceManager = await this.deviceManager()
    const videoDevices = await deviceManager.getCameras()

    const initialVideoDevice = videoDevices.find(
      (videoDevice) => initialVideoDeviceId == videoDevice.id
    ) || videoDevices[0]

    let videoOptions = undefined
    if (initialVideoDevice) {
      this.localVideoStream = new LocalVideoStream(initialVideoDevice)
      videoOptions = { localVideoStreams: [this.localVideoStream ] }
    } else {
        // not sure how we get there, but should block all since we don't want call without video
        console.error(`No camera device found on the system`);
    }

    let audioOptions = undefined
    const initialInputAudioDevice = (await deviceManager.getMicrophones()).find(
      (audioDevice) => audioDevice.id === initialInputAudioDeviceId
    )
    if (initialInputAudioDevice) {
      audioOptions = { localAudioStreams: [new LocalAudioStream(initialInputAudioDevice)] }
    }

    this.call = this._callAgent.join({ groupId: this.roomId }, { videoOptions, audioOptions })

    const callHelper = new AcsVisioHelper(this.roomId)
    await callHelper.subscribeToCall(this.call)

    if (aiNotetakerBookingConfig.available) {
      this.callRecordingHelper = new AcsVisioRecordingHelper(this.roomId, aiNotetakerBookingConfig, aiNotetakerBadgeStateTarget)
      await this.callRecordingHelper.subscribeToCall(this.call)
    }

    this.call.on('stateChanged',  async _ => {
      if (this.call.state === 'Connected' && window.location.href.indexOf("test_rooms") === -1) {
        await patch(`/rooms/${this.roomId}/save_acs_call_id`, {
          body: JSON.stringify({acs_call_id: this.call.id, acs_server_call_id: await this.call.info.getServerCallId()}),
        });
      }
    });
  }

  leaveCall() {
    this.call.hangUp()
  }

  toggleMuteMicrophone() {
    return this.call.isMuted ? this.call.unmute() : this.call.mute()
  }

  async toggleScreenShare() {
    try {
      if (this.call.isScreenSharingOn) {
        this.call.stopScreenSharing()
      } else {
        await this.call.startScreenSharing()
      }
    }
    catch(e) {
      // ignore it for now, user has probably canceled permission sheet and cannot share
    }
  }

  async toggleBlur() {
    const videoEffectsFeatureApi = this.localVideoStream.feature(Features.VideoEffects)
    const currentActiveEffects = videoEffectsFeatureApi.activeEffects

    if (currentActiveEffects.length) {
      await videoEffectsFeatureApi.stopEffects()
      return false
    } else {
      // importing like this to avoid an error running jest where the js containing API is loaded serverside too soon
      const BackgroundBlurEffect = (await import("@azure/communication-calling-effects")).BackgroundBlurEffect
      const blurEffect = new BackgroundBlurEffect()
      if (await blurEffect.isSupported()) {
          await videoEffectsFeatureApi.startEffects(blurEffect)
      }
      // else raise ???
      return true
    }
  }

  async cleanOnDisconnect() {
    this._callAgent?.dispose()
  }
}
