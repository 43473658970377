import { Controller } from "stimulus";

/** Stimulus controller used to gather consents and start, pause and restart recordings **/
export default class extends Controller {
  static outlets = ["visio-2"];

  async connect() {
    if (this.hasVisio2Outlet && this.visio2Outlet.visioCallAdapter) {
      await this.updateAiNotetakerBadgeStateTarget();
    }
  }

  async updateAiNotetakerBadgeStateTarget() {
    const { callRecordingHelper } = this.visio2Outlet.visioCallAdapter;
    await callRecordingHelper.updateAiNotetakerBadgeStateTarget({
      notStarted: this.visio2Outlet.aiNotetakerBadgeStateNotStartedTarget,
      inProgress: this.visio2Outlet.aiNotetakerBadgeStateInProgressTarget,
      paused: this.visio2Outlet.aiNotetakerBadgeStatePausedTarget,
      waiting: this.visio2Outlet.aiNotetakerBadgeStateWaitingTarget,
      refused: this.visio2Outlet.aiNotetakerBadgeStateRefusedTarget,
    });
  }
  async askRemoteParticipantForAiNotetakerConsent() {
    const { callRecordingHelper } = this.visio2Outlet.visioCallAdapter;
    await callRecordingHelper.askRemoteParticipantForAiNotetakerConsent();
  }

  async declineAiNotetakerConsent() {
    const { callRecordingHelper } = this.visio2Outlet.visioCallAdapter;
    await callRecordingHelper.declineAiNotetakerConsent(false);
  }

  async declineAiNotetakerConsentAndNotifyOtherUser() {
    const { callRecordingHelper } = this.visio2Outlet.visioCallAdapter;
    await callRecordingHelper.declineAiNotetakerConsent(true);
  }

  async acceptAiNotetakerConsent() {
    const { call, callRecordingHelper } = this.visio2Outlet.visioCallAdapter;
    const response = await callRecordingHelper.acceptAiNotetakerConsent();

    if (response.ok) {
      const body = await response.json;
      await callRecordingHelper.updateAiNotetakerBookingConfig(
        body.ai_notetaker_booking_config,
      );

      if (this.isRemoteParticipantsPresent) {
        if (body.ai_notetaker_booking_config.enabled) {
          await callRecordingHelper.startRecording(call);
        } else {
          await callRecordingHelper.showAiNotetakerBadgeState("notStarted");
        }
      }
    }
  }

  async pauseRecording() {
    const { callRecordingHelper } = this.visio2Outlet.visioCallAdapter;
    await callRecordingHelper.pauseRecording();
  }

  async resumeRecording() {
    const { callRecordingHelper } = this.visio2Outlet.visioCallAdapter;
    await callRecordingHelper.resumeRecording();
  }

  get isRemoteParticipantsPresent() {
    const { call } = this.visio2Outlet.visioCallAdapter;
    return call.remoteParticipants.length >= 1;
  }
}
