import { patch, post, get, destroy } from '@rails/request.js'
import React from 'react'
import { useCallback, useState, useMemo } from 'react';

import * as time from '@/utils/time'

import { Calendar, dayjsLocalizer, Views } from 'react-big-calendar'

const localizer = time.configureLocalizer(dayjsLocalizer)

// Colors used to distinguish coaches
const defaultCoachColors = [
  "rgb(255, 0, 0)",
  "rgb(255,255,0)",
  "rgb(0,234,255)",
  "rgb(170,0,255)",
  "rgb(255,127,0)",
  "rgb(191,255,0)",
  "rgb(0,149,255)",
  "rgb(255,0,170)",
  "rgb(255,212,0)",
  "rgb(106,255,0)",
  "rgb(0,64,255)",
  "rgb(237,185,185)",
  "rgb(185,215,237)",
  "rgb(231,233,185)",
  "rgb(220,185,237)",
  "rgb(185,237,224)",
  "rgb(143,35,35)",
  "rgb(35,98,143)",
  "rgb(143,106,35)",
  "rgb(107,35,143)",
  "rgb(79,143,35)",
  "rgb(115,115,115)",
  "rgb(204,204,204)"
]

const timeSlots = () => {
  const results = []
  for (let index = 0; index <= 48; index += 1) {
    results.push({ value: index * 30, label: time.hourLabel(index) })
  }
  return results
}

const EditModal = ({ closeModal, event, translations, user_token }) => {
  const {
    slot_day,
    initialStartTime,
    initialEndTime,
    recurrence
  } = useMemo(() => ({
    slot_day: time.shortDate(event?.start),
    initialStartTime: time.minutesFromBeginningOfDay(event?.start),
    initialEndTime: time.minutesFromBeginningOfDay(event?.end),
    recurrence: event?.recurrence
  }), [event])

  const [startTime, setStartTime] = useState(initialStartTime)
  const [endTime, setEndTime] = useState(initialEndTime)

  const handleSubmit = useCallback(
    () => {
      patch(`/availabilities/${event.id}`, {
        contentType: "application/json", 
        body: {
          availability: {
            start_on: time.addMinutes(event.start.toISOString(), startTime),
            end_on: time.addMinutes(event.start.toISOString(), endTime),
            user_token: user_token
          }
        }
      }).then(closeModal)
    },
    [event, startTime, endTime]
  )

  const handleDestroy = useCallback(() => {
    destroy(
      `/availabilities/${event.id}.json`,
      {
        contentType: "application/json",
        query: {
          // TL;DR: This works everywhere.
          // Injecting body as query params because body parsing behavior is not
          // clearly defined in the HTTP spec, leading to servers reacting in
          // different and unexpected ways.
          'availability[id]': event.id,
          'availability[start_on]': time.addMinutes(event.start.toISOString(), startTime),
          'availability[end_on]': time.addMinutes(event.end.toISOString(), endTime),
        }
      }
    ).then(closeModal)
  },
    [event, startTime, endTime]
  )

  return (
    <div className="modal custom-modal calendar-set-time" id="availabilty-form">
      <div className="resource-modal-dialog modal-dialog modal-dialog-centered modal-calendar-event" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
        </div>
        <div className="modal-body">
          <h4 className="modal-title">Disponibilité</h4>
          <div className="calendar-set-time__select-wrapper">
            <span className="calendar-set-time__date">Le: {slot_day},</span>
            {
              endTime == initialEndTime ?
              (
                <select className="calendar-set-time__select" name="slot_start_on" value={startTime} onChange={e => setStartTime(e.target.value)}>
                  {
                    timeSlots().filter((time) => ((time.value < endTime) && (!event.upperLimit || (event.upperLimit <= time.value)))).map((time, index) => (
                      <option value={time.value} key={`start_time ${time.value} _ ${index}`}>{time.label}</option>
                      )
                    )
                  }
                </select>
              ) : <span className="calendar-set-time__select--readonly">{timeSlots().find((slot) => (slot.value == startTime)).label}</span>
            }
            <span className="calendar-set-time__separate-select">-</span>
            {
              startTime == initialStartTime ?
              (
                <select className="calendar-set-time__select" name="slot_end_on" value={endTime} onChange={e => setEndTime(e.target.value)}>
                  {
                    timeSlots().filter((time) => ((time.value > startTime) && (!event.lowerLimit || (event.lowerLimit >= time.value)))).map((time, index) => (
                      <option value={time.value} key={`end_time ${time.value} _ ${index}`}>{time.label}</option>
                      )
                    )
                  }
                </select>
              ) : <span className="calendar-set-time__select--readonly">{timeSlots().find((slot) => (slot.value == endTime)).label}</span>
            }
            {
              startTime != initialStartTime || endTime != initialEndTime ?
              (
                <p className="calendar-set-time__alert-update"><i className="icon icon-warning"></i>{translations?.alert_update_slot}</p>
              ) : null
            }
          </div>
        </div>
        <div className="modal-footer">
          <button className="calendar-set-time__btn btn btn-red me-3" data-attr="delete-button" onClick={handleDestroy}>{translations?.remove}</button>
          <button className="calendar-set-time__btn btn btn-black" data-attr="submit-button" onClick={handleSubmit}>{translations?.save}</button>
        </div>
      </div>
      </div>
      <div className="custom-modal__overlay custom-modal__overlay--fullscreen" onClick={closeModal}></div>
    </div>
  )

}

const TimezoneBanner = ({ translations, show, userTimezone, guessTimezone }) => {
  return (
    <>
      {show ? (
        <div className="alert-message alert-message--position-static alert-message--warning" data-test="timezone-warning-banner">
          <i className="icon icon-warning"></i>
          <div className="alter-message__text text-start">
              <span className="d-block fw-bold mb-1">
                {translations?.tz_banner_current_tz_text}: {guessTimezone}
              </span>
              <span className="d-block fw-bold mb-1">
                {translations?.tz_banner_profile_tz_text} : {userTimezone}
              </span>
              <span className="d-block">
                {translations?.tz_banner_warning_text} <a href="/users/edit">{translations?.tz_banner_action_text}</a>
              </span>
          </div>
        </div>
      ) : (
        <div>Time zone: {userTimezone}</div>
      )}
    </>
  )
}

const CreateModal = ({ closeModal, event, translations, user_token, events }) => {
  const [recurrence, setRecurrence] = useState(false)

  const {
    slot_day,
    initialStartTime,
    initialEndTime,
  } = useMemo(() => ({
    slot_day: time.shortDate(event?.start),
    initialStartTime: time.minutesFromBeginningOfDay(event?.start),
    // Minimum availability duration is one hour
    initialEndTime: Math.max(
      time.minutesFromBeginningOfDay(event?.end),
      time.minutesFromBeginningOfDay(event?.start) + 60,
    )
  }), [event])

  const [startTime, setStartTime] = useState(initialStartTime)
  const [endTime, setEndTime] = useState(initialEndTime)

  const handleSubmit = useCallback(
    () => {
      post(
        '/availabilities',
        {
          contentType: "application/json",
          body: {
            availability: {
              start_on: time.addMinutes(event.start.toISOString(), startTime),
              end_on: time.addMinutes(event.end.toISOString(), endTime),
              user_token: user_token,
              recurrence: recurrence
            }
          }
        }
      )
      .then(closeModal)
    },
    [event, startTime, endTime, recurrence]
  )

  return (
    <div className="modal custom-modal calendar-set-time" id="availabilty-form">
      <div className="resource-modal-dialog modal-dialog modal-dialog-centered modal-calendar-event" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
        </div>
        <div className="modal-body">
          <h4 className="modal-title">Disponibilité</h4>
          <div className="calendar-set-time__select-wrapper">
            <span className="calendar-set-time__date">Le: {slot_day},</span>
            <select className="calendar-set-time__select" name="slot_start_on" value={startTime} onChange={e => setStartTime(e.target.value)}>
              {
                timeSlots().filter((time) => (time.value < endTime)).map((time, index) => (
                  <option value={time.value} key={`start_time ${time.value} _ ${index}`}>{time.label}</option>
                  )
                )
              }
            </select>
            <span className="calendar-set-time__separate-select">-</span>
            <select className="calendar-set-time__select" name="slot_end_on" value={endTime} onChange={e => setEndTime(e.target.value)}>
              {
                timeSlots().filter((time) => (time.value > startTime)).map((time, index) => (
                  <option value={time.value} key={`end_time ${time.value} _ ${index}`}>{time.label}</option>
                  )
                )
              }
            </select>
            <label className="calendar-set-time__label">
              <input name="slot_recurrence" type="checkbox" checked={recurrence} onChange={() => setRecurrence(!recurrence)}/>
              <span>{translations?.recurrent_availability}</span>
            </label>
          </div>
        </div>
        <div className="modal-footer">
          <button className="calendar-set-time__btn btn btn-black" data-attr="submit-button" onClick={handleSubmit}>{translations?.save}</button>
        </div>
      </div>
      </div>
      <div className="custom-modal__overlay custom-modal__overlay--fullscreen" onClick={closeModal}></div>
    </div>
  )
}

const resolveColor = (event, coach) => {
  return coach ?
    { color: coach.color || event.hex_color, className: event.booked_by_id ? "availability-booked" : "availability-no-booked" } :
    { color: event.booked_by_id ? '#41B491' : '#C9D3F5' }
}

export default function AvailabilityCalendar({ locale, translations, featured_coachs, coach_slots, user_id, readonly, timezone }) {
  time.locale(locale || 'en')

  const [userTimezone, setUserTimezone] = useState(timezone || time.guessTimezone())
  const [guessTimezone, setGuessTimezone] = useState(time.guessTimezone())
  const [date, setDate] = useState(time.getDateWithTimezone())

  time.setDefaultTimezone(guessTimezone)

  const parseCoaches = (coachsRawData) => {
    const coachs = {}
    coachsRawData.forEach((coach, i) => {
      coachs[coach.id] = {
        color: defaultCoachColors[i] || coach.hex_color,
        name: coach.name,
        id: coach.id
      }
    })
    return coachs
  }
  const [coachs, setCoaches] = useState(parseCoaches(featured_coachs))

  const parseEvents = (coach_slots, featured_coachs) => {
    const perCoach = {}
    const events = []
    coach_slots.forEach((event) => {
      const payload = {
        id: event.availability_id,
        coachId: event.coach_id,
        title: event.title,
        start: new Date(event.start),
        end: new Date(event.end),
        type: event.booked_by_id ? 'booking' : 'availability',
        ...resolveColor(event, coachs[event.coach_id])
      }
      if (!perCoach.hasOwnProperty(event.coach_id)) {
        perCoach[event.coach_id] = []
      }
      perCoach[event.coach_id].push(payload)
      events.push(payload)
    })
    featured_coachs.forEach((coach) => {
      const coachEvents = perCoach[coach.id] || []
      coachEvents.sort((a, b) => (new Date(a.start) - new Date(b.start)))
      let previous = coachEvents[0]
      for (let i = 1; coachEvents[i]; i += 1) {
        const current = coachEvents[i]
        if (new Date(previous.start).toDateString() == new Date(current.start).toDateString()) {
          previous.lowerLimit = time.minutesFromBeginningOfDay(current.start)
          current.upperLimit = time.minutesFromBeginningOfDay(previous.end)
        }
        previous = current
      }
    })
    return events
  }

  const [myEvents, setMyEvents] = useState(parseEvents(coach_slots, featured_coachs))
  const [editedEvent, setEditedEvent] = useState(null)
  const [createdEvent, setCreatedEvent] = useState(null)
  const [selectedCoach, setSelectedCoach] = useState(null)

  const fetchCoachSlots = (date) => {
    const query = { start_on: time.startOfWeek(date), end_on: time.endOfWeek(date) }

    if (featured_coachs.length > 0)
      query.featured_coachs = featured_coachs.map((e) => e.id)

    get(`/profiles/${user_id}/fetch_coach_slots.json`, { contentType: 'application/json', query })
      .then((response) => response.json)
      .then((result) => setMyEvents(parseEvents(result.coach_slots, featured_coachs)))
  }

  const eventPropGetter = useCallback((event) => {
    return {
      className: event.className,
      style: { backgroundColor: event.color }
    }
  }, [myEvents]);

  const onNavigate = useCallback((newDate) => {
    const newDateTz = time.getDateWithTimezone(newDate);

    setDate(newDateTz)
    fetchCoachSlots(newDateTz)
  })

  const selectSlot = useCallback((slot) => {
    if (!readonly) {
      setCreatedEvent(slot)
    }
  })
  const selectEvent = useCallback((slot) => { setEditedEvent(slot) })
  const closeModal = useCallback(() => {
    fetchCoachSlots(date)
    setEditedEvent(null)
    setCreatedEvent(null)
  }, [date])

  return (
    <div data-attr="calendar">
      <div className="text-center p-2">
        <TimezoneBanner
          translations={translations}
          show={guessTimezone != userTimezone}
          guessTimezone={guessTimezone}
          userTimezone={userTimezone}
        />
      </div>
      <div className="admin-calendar-featured-coachs" data-attr='admin-calendar-featured-coachs'>
        {Object.values(coachs).filter(coach => selectedCoach ? coach.id == selectedCoach : true).map(function(coach, index) {
          return(
              <div className="featured-coach" key={'coach_' + coach.id} data-attr='featured-coach' onClick={() => { setSelectedCoach(coach.id == selectedCoach ? null : coach.id) }}>
                <div className="featured-coach-hex" style={{backgroundColor:coach.color}}></div>
                {coach.name}
              </div>
            )
          })}
      </div>

      <Calendar
        // Force display mode to week only
        defaultView={Views.WEEK}
        views={[Views.WEEK]}
        // I18n management
        localizer={localizer}
        locale={locale}
        translations={translations}
        culture={locale}
        today={translations.today}
        messages={{...translations}}
        // Displayed content
        events={myEvents.filter((event) => selectedCoach == null || event.coachId == selectedCoach)}
        eventPropGetter={eventPropGetter}
        // Behavior
        onNavigate={onNavigate}
        onSelectSlot={selectSlot}
        onSelectEvent={selectEvent}
        step={30}
        timeslots={2}
        selectable
        drilldownView={false}
      />
      {editedEvent ? (
        <EditModal
          closeModal={closeModal}
          event={editedEvent}
          translations={translations}
          user_token={user_id}
        />
      ) : null}
      {createdEvent ? (
        <CreateModal
          event={createdEvent}
          events={myEvents.filter((event) => event.coachId == user_id)}
          closeModal={closeModal}
          translations={translations}
          user_token={user_id}
        />
      ) : null}
    </div>
  )
}
