import consumer from "./consumer"
import { patch } from '@rails/request.js'

function scrollLastMessageIntoView() {
  const messages = document.querySelectorAll('.chat-room-message');
  const lastMessage = messages[messages.length - 1];

  if (lastMessage !== undefined) {
    lastMessage.scrollIntoView();
  }
}

function refreshChat(messageHTML) {
  const messagesContainer = document.querySelector('.chat-room-messages');
  messagesContainer.insertAdjacentHTML('beforeend', messageHTML);
  document.getElementById("new_message").reset();
  const input = document.querySelector('#message_content');
  input.blur();
  scrollLastMessageIntoView();
}

function seenNewMessages(channelId) {
  patch(`/chat_rooms/${channelId}/messages/read_all.json`)
}

function notif(chat_room_id) {
  const unread_messages_count_div = document.querySelector('.notification');
  const mobileNotif = document.querySelector('.mobile_notif');
  const messageNotifVisio = document.querySelector('.message_notif_visio');
  const user_list = document.querySelector('.user[data-chat-room="' +String(chat_room_id)+'"] > .fa-circle');

  if (mobileNotif) {
    mobileNotif.classList.add('notif');
  }

  if (messageNotifVisio) {
    messageNotifVisio.classList.remove('d-none');
  }

  if (unread_messages_count_div){
    unread_messages_count_div.innerHTML = (parseInt(unread_messages_count_div.innerHTML)+1)
    document.querySelector('title').innerHTML = "("+parseInt(unread_messages_count_div.innerHTML) +") Simundia"
  }

  if (user_list){
    user_list.classList.add('notification');
  }

  const audio = document.querySelector('#notification-sound');
  if (audio){
    audio.play();
  }
}


export { scrollLastMessageIntoView, seenNewMessages, notif};




