import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "errors",
    "firstNameInput",
    "emailInput",
    "feedbackRequest",
  ];
  static values = {
    changedForm: String,
    notValidated: String,
    confirmationMessage: String,
  };

  close() {
    this.errorsTarget.innerHTML = "";
  }
  // invoked via a custom event fired when bs start closing the modal
  checkBeforeClose(e) {
    const unvalidatedNames = this.getUnvalidatedFeedbackRequests();

    if (this.formHasChanges()) {
      if (
        !window.confirm(
          `${this.changedFormValue} ${this.firstNameInputTarget.value} ${this.emailInputTarget.value}\n${this.confirmationMessageValue}`,
        )
      ) {
        e.detail.modalCloseEvent.preventDefault();
      }
    } else if (unvalidatedNames.length > 0) {
      if (
        !window.confirm(
          `${this.notValidatedValue} ${unvalidatedNames.join(", ")}\n${this.confirmationMessageValue}`,
        )
      ) {
        e.detail.modalCloseEvent.preventDefault();
      }
    }
  }

  formHasChanges() {
    let formInputs = [this.firstNameInputTarget, this.emailInputTarget];
    return formInputs.some((input) => input.value != "");
  }

  getUnvalidatedFeedbackRequests() {
    return this.feedbackRequestTargets
      .filter((el) => el.dataset.feedbackRequestValidated === "false")
      .map((el) =>
        el.querySelector("[data-test='360-firstname']").textContent.trim(),
      );
  }
}
