import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    // Target are the radio-buttons labels. Didn't use data-target as labels are generated by simpleform
    // and we don't have control over their html
    const options = Array.from(
      this.element.getElementsByClassName("collection_radio_buttons"),
    );

    // Attach a click event listener to each label, that submits the form
    options.forEach((option) => {
      option.addEventListener("click", this.clickSubmitsForm.bind(this));
    });
  }

  clickSubmitsForm() {
    const AUTO_SUBMIT_SPEED = 100; // you can change this to increase or decrease the submission speed
    const theForm = document.querySelector(".multipage-form");

    setTimeout(function () {
      theForm.submit();
    }, AUTO_SUBMIT_SPEED);
  }
}
