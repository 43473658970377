import { patch } from "@rails/request.js";
import { Controller } from "stimulus";

export default class extends Controller {
  static values = { url: String, dictionaries: Object };

  static targets = ["pictureContainer", "pictureStateButton"];

  pictureChange(event) {
    const file = event.target.files[0];
    if (file === undefined) {
      return false;
    }

    this.pictureStateButtonTarget.innerHTML = this.dictionariesValue.loading;
    event.target.disabled = true;

    const formData = new FormData();
    formData.append("user[picture]", file);

    patch(this.urlValue, {
      body: formData,
      responseKind: "json",
    })
      .then((response) => response.json)
      .then((element) => {
        event.target.disabled = false;
        this.pictureContainerTarget.style.backgroundImage = `url(${element.picture_url})`;
        this.pictureStateButtonTarget.innerHTML =
          this.dictionariesValue.click_to_modify;
      });
  }
}
