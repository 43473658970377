import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import * as bootstrap from "bootstrap";
export default class extends Controller {
  static values = {
    modalId: String,
    href: String,
    emphasize: Boolean,
    resource: Object,
  };

  connect() {
    window.addEventListener("popstate", this.popStateHandler);

    this.$modal = document.querySelector(this.modalIdValue);
    this.$hideHandler = this.reset.bind(this);

    if (this.emphasizeValue) {
      this.open();
    }
  }

  disconnect() {
    window.removeEventListener("popstate", this.popStateHandler);
  }

  popStateHandler(event) {
    let resourceModalState = event.state.resourceModal;
    if (resourceModalState && resourceModalState.refreshOnBack) {
      window.location.reload();
    }
  }

  open() {
    const bootstrapModal = new bootstrap.Modal(this.$modal);
    const href = this.hrefValue;

    const onSuccess = function (response) {
      // populate the modal
      document.querySelector("#exampleModal .modal-content").innerHTML =
        response.body.innerHTML;
    };

    Rails.ajax({
      type: "GET",
      url: href,
      dataType: "html",
      success: onSuccess,
    });

    this.$modal.addEventListener("hidden.bs.modal", this.$hideHandler);
    this.push_url(this.emphasize_href(this.resourceValue.url_token));
    bootstrapModal.show();

    // push resources characteristics to GTM DataLayer
    window.dataLayer.push({
      event: "resource-seen",
      resource_id: this.resourceValue.id,
      resource_title: this.resourceValue.title,
      resource_format: this.resourceValue.format,
      resource_language: this.resourceValue.language,
    });
  }
  reset() {
    this.$modal.getElementsByClassName("modal-content")[0].innerHTML = "";
    this.push_url(this.emphasize_href(""));
    this.$modal.removeEventListener("hidden.bs.modal", this.$hideHandler);
  }

  // and or removes the emphasize parameter to the current url
  emphasize_href(token) {
    let url = new URL(window.location.href);
    if (token) {
      url.searchParams.set("emphasize", this.resourceValue.url_token);
    } else {
      url.searchParams.delete("emphasize");
    }

    return url.href;
  }

  // update the url displayed in the the browser
  push_url(href) {
    if (window.location.href != href) {
      window.history.pushState(
        { resourceModal: { refreshOnBack: true } },
        "",
        href,
      );
    }
  }
}
