import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["firstGrade", "tagManager", "answer"];

  convert(grade) {
    const lastGrade = parseInt(grade.target.innerText);
    const amplitude = lastGrade - parseInt(this.firstGradeTarget.innerText);
    const tagManagers = this.tagManagerTargets;

    if (amplitude <= 0) {
      this.removeActive(tagManagers);
      const index = 0;
      this.addActive(tagManagers, index);
      this.postAnswer(tagManagers, index);
    } else if (amplitude > 7 && amplitude <= 10) {
      this.removeActive(tagManagers);
      const index = 5;
      this.addActive(tagManagers, index);
      this.postAnswer(tagManagers, index);
    } else if (lastGrade <= 5 && (amplitude == 1 || amplitude == 2)) {
      this.removeActive(tagManagers);
      const index = 0;
      this.addActive(tagManagers, index);
      this.postAnswer(tagManagers, index);
    } else if (
      lastGrade > 5 &&
      lastGrade < 8 &&
      (amplitude == 1 || amplitude == 2)
    ) {
      this.removeActive(tagManagers);
      let index = 1;
      this.addActive(tagManagers, index);
      this.postAnswer(tagManagers, index);
    } else if (lastGrade >= 8 && (amplitude == 1 || amplitude == 2)) {
      this.removeActive(tagManagers);
      let index = 2;
      this.addActive(tagManagers, index);
      this.postAnswer(tagManagers, index);
    } else if (lastGrade <= 5 && amplitude >= 3 && amplitude <= 6) {
      this.removeActive(tagManagers);
      let index = 3;
      this.addActive(tagManagers, index);
      this.postAnswer(tagManagers, index);
    } else if (
      lastGrade > 5 &&
      lastGrade <= 10 &&
      amplitude >= 3 &&
      amplitude <= 6
    ) {
      this.removeActive(tagManagers);
      let index = 4;
      this.addActive(tagManagers, index);
      this.postAnswer(tagManagers, index);
    }
  }

  removeActive(tagManagers) {
    tagManagers.forEach((tagManager) => {
      tagManager.classList.remove("active");
    });
  }

  addActive(tagManagers, index) {
    return tagManagers[index].classList.add("active");
  }

  postAnswer(tagManagers, index) {
    this.answerTarget.value = tagManagers[index].innerText;
  }

  activate(event) {
    this.tagManagerTargets.forEach((tagManagerActive) => {
      tagManagerActive.classList.remove("active");
    });
    event.currentTarget.classList.add("active");
    this.answerTarget.value = event.currentTarget.innerText;
  }
}
