import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source"];
  copy(e) {
    e.stopPropagation();
    navigator.clipboard.writeText(
      e.target.dataset.value || this.sourceTarget.innerHTML,
    );
  }
}
