import AcsVisioCallAdapter from './acs_visio_call_adapter';
import TestVisioCallAdapter from './test_visio_call_adapter';

export function getVisioCallAdapter(serviceIdentifier, acsAccessToken, roomId, testScenario) {
    switch (serviceIdentifier) {
        case 'acs':
            return new AcsVisioCallAdapter(acsAccessToken, roomId)
        case 'test':
            return new TestVisioCallAdapter(roomId, testScenario);
        default:
            throw new Error('Unsupported visio call service');
    }
}
