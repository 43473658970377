import { Controller } from "stimulus";
import Chart from "chart.js/auto";

export default class extends Controller {
  static values = { counters: Array, labels: Array };

  connect() {
    const data = {
      datasets: [
        {
          data: this.countersValue[0],
          backgroundColor: "#F8D769",
          stack: "Stack 0",
        },
        {
          data: [null, null, null, null, null].concat(this.countersValue[1]),
          backgroundColor: "#87D4BD",
          stack: "Stack 0",
        },
      ],
      labels: this.labelsValue,
    };
    const ctx = this.element.getContext("2d");
    ctx.height = 750;
    this.chart = new Chart(ctx, {
      type: "bar",
      data: data,
      options: {
        indexAxis: "y",
        barThickness: 20,
        plugins: { legend: { display: false } },
      },
    });
  }

  disconnect() {
    if (this.chart) this.chart.destroy();
  }
}
