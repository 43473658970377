import { scrollLastMessageIntoView, seenNewMessages } from '../channels/chat_room_channel'

const chatRoomDisplay = (chatRoomId) => {
  const visioComment = document.getElementById('visio-comment');
  const visioCommentFull = document.getElementById('visio-comment-full');
  const messageNotifVisio = document.querySelector('.message_notif_visio');

  if (messageNotifVisio) {
    visioComment.addEventListener('click', (event) => showChat(event, chatRoomId));
    visioCommentFull.addEventListener('click', (event) => hideChat(event));

    document.getElementById('shortcut-open-close-chat').addEventListener('click', (event) => {
      if(document.getElementById('chat-room').classList.contains("hidden-chat-room")) {
        showChat(event, chatRoomId)
      } else {
        hideChat(event);
      }
    });
  } else {
    scrollLastMessageIntoView();
    seenNewMessages(chatRoomId);
  }
}

function showChat(event, chatRoomId) {
  document.querySelector('.message_notif_visio').classList.remove("notif");
  document.getElementById('visio-comment').classList.add("d-none");
  document.querySelector('.video').classList.add("small-video");
  document.getElementById('visio-comment-full').classList.remove("d-none");
  document.getElementById('chat-room').classList.remove("hidden-chat-room");
  scrollLastMessageIntoView();
  seenNewMessages(chatRoomId);
}

function hideChat(event) {
  document.getElementById('chat-room').classList.add("hidden-chat-room");
  document.querySelector('.video').classList.remove("small-video");
  document.getElementById('visio-comment-full').classList.add("d-none");
  document.getElementById('visio-comment').classList.remove("d-none");
}

export { chatRoomDisplay };
