import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["adblockMsg"];

  async connect() {
    this.alertComponentWrapper =
      document.querySelector(".alert-component-wrapper") || null;

    if (this.adblockMsgTarget) {
      try {
        await fetch(
          "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
        );
        this.hideErrorMsg();
      } catch (error) {
        this.showErrorMsg();
      }
    }
  }

  showErrorMsg() {
    this.adblockMsgTarget.classList.remove("d-none");

    if (this.alertComponentWrapper) {
      this.alertComponentWrapper.classList.remove("d-none");
    }
  }

  hideErrorMsg() {
    this.adblockMsgTarget.classList.add("d-none");
  }
}
