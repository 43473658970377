import { Controller } from "stimulus";
import { destroy } from "@rails/request.js";

export default class extends Controller {
  static values = {
    path: String,
  };

  async perform() {
    const response = await destroy(this.pathValue, {
      contentType: "application/json",
    });
    if (response.ok) {
      const data = await response.json;
      if (data.redirect_url) {
        window.location.href = data.redirect_url;
      }
    }
  }
}
