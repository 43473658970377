import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["form", "messageWrapper"];

  onSubmit(e) {
    e.preventDefault();

    var form = this.formTarget;
    var msgWrapper = this.messageWrapperTarget;
    var action = form.getAttribute("action");
    var method = form.getAttribute("method");
    var formData = new FormData(form);

    form.classList.add("-loading");

    Rails.ajax({
      type: method,
      data: formData,
      dataType: "json",
      processData: false,
      contentType: false,
      url: action,
      success: (response) => {
        msgWrapper.innerHTML = response.message;
        msgWrapper.classList.add("-success");
        msgWrapper.classList.remove("-error");
        form.classList.remove("-loading");
      },
      error: (err) => {
        msgWrapper.innerHTML = err.message;
        msgWrapper.classList.remove("-success");
        msgWrapper.classList.add("-error");
        form.classList.remove("-loading");
      },
    });
  }
}
