import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submit"];

  delayEnableBtn() {
    // disabling by default the btn, usefull for the second time it is clicked as rails will not disable it by default
    this.submitTarget.disabled = true;
    setTimeout(() => {
      this.submitTarget.disabled = false;
    }, 2000);
  }
}
