import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["timelineBlock"];

  connect() {
    const timelineBlocks = this.timelineBlockTargets;

    setTimeout(() => {
      let targetTimelineBlock = null;
      for (const block of timelineBlocks) {
        if (block.getAttribute("data-block-status") != "past-block") {
          targetTimelineBlock = block;
          break;
        }
      }

      if (targetTimelineBlock) {
        targetTimelineBlock.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 0);
  }
}
