import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["parent"];

  initializeScrollTo() {
    this.parentTarget
      ?.querySelector(".active")
      ?.scrollIntoView({ behavior: "smooth" });
  }
}
