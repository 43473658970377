import { Controller } from "stimulus";
import { useWindowResize } from "stimulus-use";

export default class extends Controller {
  static targets = ["wrapper", "content"];

  static values = {
    index: Number,
    anchorNavigation: Boolean,
  };

  connect() {
    if (this.anchorNavigationValue) {
      window.addEventListener("hashchange", this.navigateBrowserButtons, false);
    }
  }

  disconnect() {
    if (this.anchorNavigationValue) {
      window.removeEventListener("hashchange", this.navigateBrowserButtons);
    }
  }

  initialize() {
    this.index = this.indexValue;
    setTimeout(this.goToIndex(), 0);
    useWindowResize(this);
  }

  goToPrev() {
    const currentIndex = this.index;
    const prevIndex = currentIndex - 1;
    const prevContent = this.wrapperTarget.querySelectorAll(
      ".slider-content__content",
    )[prevIndex];

    if (prevContent) {
      this.index = prevIndex;
    }
  }

  navigateBrowserButtons = () => {
    if (location.hash === "") {
      this.index = 0;
    } else {
      this.index = this.findIndexByAnchor(location.hash.replace(/^#/, ""));
    }
    this.goToIndex();
  };

  goToNext() {
    const currentIndex = this.index;
    const nextIndex = currentIndex + 1;
    const nextContent = this.wrapperTarget.querySelectorAll(
      ".slider-content__content",
    )[nextIndex];

    if (nextContent) {
      this.index = nextIndex;
    }
  }

  findIndexByAnchor(anchorId) {
    return [].indexOf.call(
      this.wrapperTarget.querySelectorAll(".slider-content__content"),
      document.querySelector('[data-slider-content-anchor="' + anchorId + '"]'),
    );
  }

  goToIndexWithAnchor(event) {
    this.index = this.findIndexByAnchor(
      event?.currentTarget.dataset.sliderContentAnchorId,
    );
    location.hash = event?.currentTarget.dataset.sliderContentAnchorId;
  }

  goToIndex(event) {
    if (event?.currentTarget.dataset.sliderContentIndex) {
      this.index = event?.currentTarget.dataset.sliderContentIndex;
    }
    const content = this.wrapperTarget.querySelectorAll(
      ".slider-content__content",
    )[this.index];
    const scrollTop = content.offsetTop;

    this.contentTarget.style.transform = "translateY(-" + scrollTop + "px)";

    const heightWrapper = content.offsetHeight;
    this.wrapperTarget.style.height = heightWrapper + "px";
  }

  windowResize() {
    this.goToIndex();
  }

  get index() {
    return parseInt(this.data.get("index") || 0);
  }

  set index(value) {
    this.data.set("index", value);
    this.goToIndex();
  }
}
