import {VideoStreamRenderer} from "@azure/communication-calling";
import VisioDisplayManager from "./visio_display_manager";

export default class AcsVisioHelper {
    constructor(roomId) {
        this.visioDisplayManager = new VisioDisplayManager()
        this.roomId = roomId
    }

    async subscribeToCall(call) {
        this.call = call
        this.isAnyoneHere()
        for (const localVideoStream of call.localVideoStreams) {
            await this.addLocalVideoStream(localVideoStream);
        }
        call.on('localVideoStreamsUpdated', async e => {
            for (const localVideoStream of e.added) {
                await this.addLocalVideoStream(localVideoStream);
            }
            e.removed.forEach(localVideoStream => {
                this.removeLocalVideoStream(localVideoStream);
            });
        });
        // Subscribe to the call's 'remoteParticipantsUpdated' event to be
        // notified when new participants are added to the call or removed from the call.
        call.on('remoteParticipantsUpdated', async e => {

            // Subscribe to new remote participants that are added to the call.
            for (const remoteParticipant of e.added) {
                await this.subscribeToRemoteParticipant(remoteParticipant)
                this.visioDisplayManager.hideWaitingForOtherUserMsg()
            }
            e.removed.forEach(remoteParticipant => {
                if (remoteParticipant._call.callEndReason === undefined) {
                    this.visioDisplayManager.showWaitingForOtherUserMsg()
                }
            });
        });
        call.on('isMutedChanged', () => {
            this.visioDisplayManager.toggleMuteMicrophoneButtonState()
        });
    }

    async subscribeToRemoteParticipant(remoteParticipant) {
        for (const remoteVideoStream of remoteParticipant.videoStreams) {
            await this.subscribeToRemoteVideoStream(remoteVideoStream)
        }
        remoteParticipant.on('videoStreamsUpdated', async e => {
            for (const remoteVideoStream of e.added) {
                await this.subscribeToRemoteVideoStream(remoteVideoStream)
            }
            for (const remoteVideoStream of e.removed) {
                await this.removeRemoteVideoStream(remoteVideoStream)
            }
        });
    }

    async subscribeToRemoteVideoStream(remoteVideoStream) {
        remoteVideoStream.on('isAvailableChanged', async () => {
            if (remoteVideoStream.isAvailable) {
                await this.addRemoteVideoStream(remoteVideoStream)
            } else {
                await this.removeRemoteVideoStream(remoteVideoStream)
            }
        });
        // Remote participant has video on initially.
        if (remoteVideoStream.isAvailable) {
            await this.addRemoteVideoStream(remoteVideoStream)
        }
    }

    async addRemoteVideoStream(videoStream) {
        const videoStreamRenderer = new VideoStreamRenderer(videoStream);
        const streamView = this.addStyleToVideoStream(await videoStreamRenderer.createView());
        const remoteScreensharing = this.findRemoteVideoStream('ScreenSharing')
        const useSmallContainer = videoStream.mediaStreamType === 'Video' && remoteScreensharing !== null && remoteScreensharing !== undefined
        this.visioDisplayManager.displayStream(videoStream.mediaStreamType.toLowerCase(), false, streamView.target, useSmallContainer)

        if (videoStream.mediaStreamType === 'ScreenSharing') {
            // remote/incoming screensharing stream, also display the camera video of the remote user in small
            const remoteCameraStream = this.findRemoteVideoStream('Video')
            if (remoteCameraStream) {
                await this.addRemoteVideoStream(remoteCameraStream);
            }
        }
    }

    async addLocalVideoStream(videoStream) {
        const videoStreamRenderer = new VideoStreamRenderer(videoStream);
        const streamView = this.addStyleToVideoStream(await videoStreamRenderer.createView());
        this.visioDisplayManager.displayStream(videoStream.mediaStreamType.toLowerCase(), true, streamView.target)

        if (videoStream.mediaStreamType === 'ScreenSharing') {
            // local screensharing just started, toggle the state of the screensharing button
            this.visioDisplayManager.toggleScreenShareButton(true)
        }
    }

    async removeRemoteVideoStream(remoteVideoStream) {
        if (remoteVideoStream.mediaStreamType === 'ScreenSharing') {
            // attempt to move back the remote user camera video to the main video container
            this.visioDisplayManager.removeStream('video', false, true)
            const remoteCameraStream = this.findRemoteVideoStream('Video')
            if (remoteCameraStream) {
               await this.addRemoteVideoStream(remoteCameraStream);
            }
        }
        else {
            this.visioDisplayManager.removeStream('video', false, false)
        }
    }

     removeLocalVideoStream(localVideoStream) {
        if (localVideoStream.mediaStreamType === 'ScreenSharing') {
            this.visioDisplayManager.toggleScreenShareButton(false)
        }
    }

    addStyleToVideoStream(streamView) {
        streamView.target.getElementsByTagName('video')[0].setAttribute('playsInline', 'true')
        streamView.target.getElementsByTagName('video')[0].setAttribute('style', 'width: 100%; height: 100%; object-fit: fill;')
        return streamView
    }

    findRemoteVideoStream(mediaStreamType) {
        return this.call.remoteParticipants[0]?.videoStreams.find(stream => {
            return stream.mediaStreamType === mediaStreamType && stream.isAvailable;
        });
    }

    isAnyoneHere() {
        setTimeout(() => {
            if (!this.call.remoteParticipants[0]) {
                this.visioDisplayManager.showWaitingForOtherUserMsg()
            }
        }, 2000);
    };
}
