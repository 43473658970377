import { Controller } from "stimulus"

export default class extends Controller {
  static values = { config: String }

  initialize() {
    // For every page load, see if Botpress JS is loaded. If not, load it.
    // If so, reinitialize it to work with the reloaded page.
    if (this.configValue) {
      this.config = JSON.parse(this.configValue);
      if (this.botpressChatIsLoaded()) {
        this.initBotpressWebChat(false);
      } else {
        this.loadBotpressWebChat();
      }
    }
  }

  botpressChatIsLoaded() {
    return !!window.botpressWebChat;
  }

  initBotpressWebChat(open_chat) {
    if (import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test') {
      // cannot get from localhost
      this.config.avatar_url = ''
    }

    window.botpressWebChat.init({
      "userData": {
        "id": this.config.user_id
      },
      "composerPlaceholder": this.config.dictionaries.composer_placeholder,
      "botConversationDescription": this.config.dictionaries.bot_conversation_description,
      "botId": this.config.init.bot_id,
      "hostUrl": "https://cdn.botpress.cloud/webchat/v1",
      "messagingUrl": "https://messaging.botpress.cloud",
      "clientId": this.config.init.client_id,
      "webhookId": this.config.init.webhook_id,
      "lazySocket": true,
      "themeName": "prism",
      "botName": this.config.dictionaries.bot_name,
      "avatarUrl": this.config.avatar_url,
      "frontendVersion": "v1",
      "useSessionStorage": true,
      "showBotInfoPage": true,
      "theme": "prism",
      "stylesheet": this.config.init.stylesheet
    })

    window.botpressWebChat.onEvent(
      (event) => {
        if (event.type === 'LIFECYCLE.LOADED' && open_chat === true) {
          window.botpressWebChat.sendEvent({ type: 'show' })
        }
      },
      ['LIFECYCLE.LOADED']
    )
  }

  loadBotpressWebChat() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => this.initBotpressWebChat(true);
    script.src =
      'https://cdn.botpress.cloud/webchat/v1/inject.js';
    document.getElementsByTagName('head')[0].appendChild(script);
  }
} 
