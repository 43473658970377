import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Resource from './resource';

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_text: '',
      search_filter: this.props.search_filter,
      main_categories: this.props.main_categories,
      softskills: this.props.softskills,
      search_lang: '',
      search_format: '',
      formatList: []
    };
  }

  componentDidMount = () => {
    const formatList = [...new Set(this.props.resources.map(resource => resource.format))]
    this.setState({formatList})
  }

  handleFilterChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  render() {
    let filteredResources = this.props.resources.filter(
      (resource) => {
        const search_text = this.state.search_text.toLowerCase();
        const resource_found_by_search_text = (resource.title.toLowerCase().includes(search_text) || resource.author.toLowerCase().includes(search_text));
        const search_filter = this.state.search_filter;

        const search = search_text.length >= 3 ? resource_found_by_search_text : true
        const lang = this.state.search_lang !== '' ? (resource.locale === this.state.search_lang) : true
        const format = this.state.search_format !== '' ? (resource.format === this.state.search_format) : true
        const softskill = search_filter !== '' ? resource.softskills_name.includes(search_filter) : true
        return search && softskill && lang && format
      }
    )

    return(
      <div>
        <div className='search_bar'>
          <input
            type='text'
            placeholder='Rechercher par titre ou auteur'
            value={this.state.search_text}
            onChange={this.handleFilterChange}
            name="search_text"
          />

            <select className='select_field' value={this.state.search_filter} onChange={this.handleFilterChange} name="search_filter">
            <option value=""></option>
                {this.props.softskills.map(softskill => {
                    return <option key={softskill.name} value={softskill.name}>{softskill.name}</option>;
                })}
            </select>

          <select className="select_field" value={this.state.search_format} onChange={this.handleFilterChange} name="search_format">
            <option value=""></option>
            {this.state.formatList.map(format => {
              return <option key={format} value={format}>{format}</option>;
            })}
          </select>
          <select className="select_field" value={this.state.search_lang} onChange={this.handleFilterChange} name="search_lang">
            <option value=""></option>
            {this.props.resource_locales.map(lang => {
              return <option key={lang} value={lang}>{lang}</option>
            })}
          </select>
        </div>
        <div className='resource-cards'>
            {filteredResources.map(resource => {
              return <Resource
                selectedResources={this.props.selectedResources}
                selectResource={this.props.selectResource}
                resource={resource}
                key={resource.id}
              />;
            })}
        </div>
      </div>
    );
  }
}
