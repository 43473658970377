import { Controller } from "stimulus";

export default class extends Controller {
  copyToClipboard(event) {
    const text = event.currentTarget.getAttribute("data-url");
    navigator.clipboard.writeText(text).then(
      function () {
        alert("URL copiée dans le presse-papiers");
      },
      function (err) {
        alert("Erreur lors de la copie :", err);
      },
    );
  }
}
