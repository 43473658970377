import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom";
import Library from "@/components/resources/library";

export default class extends Controller {
  static values = { dataset: Object };

  connect() {
    ReactDOM.render(
      React.createElement(Library, { dataset: this.datasetValue }),
      this.element,
    );
  }
}
