import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["email"];

  refreshOnEmailInput() {
    if (this.emailTarget.value) {
      Rails.refreshCSRFTokens();
    }
  }
}
