import { Controller } from "stimulus";

/**
 * Controller is using in viewcomponent input_auto_complete_component
 *
 */
export default class extends Controller {
  static targets = [
    "input",
    "inputhidden",
    "results",
    "clear",
    "readonlybutton",
    "selectedItems",
    "itemNames",
    "list",
  ];

  static values = {
    suggestions: Array,
    url: String,
    readonly: Boolean,
    multiSelect: Boolean,
  };

  connect() {
    this.currentFocus = -1;
    this.inputTarget.addEventListener("keydown", this.handleKeyDown.bind(this));
    this.clear = this.hasClearTarget ? this.clearTarget : null;
    this.readonlybutton = this.hasReadonlybuttonTarget
      ? this.readonlybuttonTarget
      : null;
    this.selectedItems = this.multiSelectValue ? [] : null;

    if (this.multiSelectValue) {
      this.dispatchItemCountEvent();
    }

    console.log("AutocompleteCustomController connected");
  }

  handleKeyDown(e) {
    const items = this.resultsTarget.getElementsByTagName("li");
    if (e.key === "ArrowDown") {
      e.preventDefault();
      this.currentFocus++;
      this.addActive(items);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      this.currentFocus--;
      this.addActive(items);
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (this.currentFocus > -1) {
        if (items[this.currentFocus]) items[this.currentFocus].click();
      } else {
        this.addToList();
      }
    }
  }

  addActive(items) {
    if (!items.length) return false;
    this.removeActive(items);
    if (this.currentFocus >= items.length) this.currentFocus = 0;
    if (this.currentFocus < 0) this.currentFocus = items.length - 1;
    items[this.currentFocus].classList.add("autocomplete-active");
  }

  removeActive(items) {
    Array.from(items).forEach((item) => {
      item.classList.remove("autocomplete-active");
    });
  }

  showElement(el) {
    if (!el) return;
    el.classList.remove("d-none");
    el.classList.add("d-block");
  }

  hideElement(el) {
    if (!el) return;
    el.classList.remove("d-block");
    el.classList.add("d-none");
  }

  async filter() {
    const query = this.inputTarget.value.toLowerCase();

    if (query.length < 2) {
      this.resultsTarget.innerHTML = "";
      this.hideElement(this.readonlybutton);
      return;
    }

    if (this.hasSuggestionsValue) {
      this.filterFromArray(query);
    }
  }

  filterFromArray(query) {
    let filteredSuggestions = this.suggestionsValue.filter((suggestion) =>
      suggestion.toLowerCase().includes(query),
    );

    if (this.multiSelectValue) {
      filteredSuggestions = filteredSuggestions.filter(
        (suggestion) => !this.selectedItems.includes(suggestion),
      );
    }

    this.renderSuggestions(filteredSuggestions, query);
  }

  renderSuggestions(suggestions, query) {
    if (suggestions.length === 0) {
      this.showElement(this.readonlybutton);
    } else {
      this.hideElement(this.readonlybutton);
    }

    this.resultsTarget.innerHTML = suggestions
      .map(
        (suggestion, index) =>
          `<li data-action="click->ui--autocomplete-custom#setInputValue" data-ui--autocomplete-custom-value="${suggestion}" data-ui--autocomplete-custom-label="${suggestion}" tabindex="${index + 1}">${this.highlightMatch(suggestion, query)}</li>`,
      )
      .join("");

    this.currentFocus = -1;
  }

  highlightMatch(text, query) {
    const regex = new RegExp(`(${query})`, "gi");
    return text.replace(regex, "<strong>$1</strong>");
  }

  setInputValue(event) {
    const selectedValue = event.currentTarget.getAttribute(
      "data-ui--autocomplete-custom-value",
    );

    if (this.multiSelectValue) {
      if (!this.selectedItems.includes(selectedValue)) {
        this.selectedItems.push(selectedValue);
        this.addSelectedItem(selectedValue);
        this.inputTarget.value = "";
        this.resultsTarget.innerHTML = "";
        this.inputTarget.focus();
        this.filter();
        this.itemNamesTarget.value = JSON.stringify(this.selectedItems);
        this.dispatchItemCountEvent();
      }
    } else {
      this.inputhiddenTarget.value = selectedValue;
      this.inputTarget.value = selectedValue;
      this.resultsTarget.innerHTML = "";
      if (this.readonlyValue) {
        this.setInputReadOnlyTrue();
      }
    }
  }

  setInputReadOnlyTrue() {
    this.inputTarget.readOnly = true;
    this.hideElement(this.readonlybutton);
    this.showElement(this.clear);
  }

  setInputReadOnlyFalse() {
    this.inputTarget.readOnly = false;
    this.inputhiddenTarget.value = "";
    this.inputTarget.value = "";
    this.hideElement(this.clearTarget);
    if (this.multiSelectValue) {
      this.selectedItems = [];
      this.itemNamesTarget.value = JSON.stringify(this.selectedItems);
      this.dispatchItemCountEvent();
    }
  }

  addToList() {
    if (!this.multiSelectValue) return;

    const input = this.inputTarget.value.trim();
    if (input === "" || this.selectedItems.includes(input)) return;

    this.selectedItems.push(input);
    this.addSelectedItem(input);
    this.inputTarget.value = "";
    this.itemNamesTarget.value = JSON.stringify(this.selectedItems);
    this.dispatchItemCountEvent();
    this.filter();
  }

  addSelectedItem(itemName) {
    if (!this.multiSelectValue) return;

    const itemElement = document.createElement("span");
    itemElement.classList.add("custom-multiselect__selected-item");
    itemElement.textContent = itemName;
    itemElement.setAttribute(
      "data-action",
      "click->ui--autocomplete-custom#removeItem",
    );

    this.listTarget.appendChild(itemElement);
  }

  removeItem(event) {
    if (!this.multiSelectValue) return;

    const itemName = event.currentTarget.textContent.trim();
    this.selectedItems = this.selectedItems.filter((item) => item !== itemName);
    event.currentTarget.remove();
    this.itemNamesTarget.value = JSON.stringify(this.selectedItems);
    this.dispatchItemCountEvent();
    this.filter();
  }

  dispatchItemCountEvent() {
    if (!this.multiSelectValue) return;

    const count = this.selectedItems.length;

    const event = new CustomEvent("ui--autocomplete-custom:itemCountChanged", {
      detail: { count },
      bubbles: true,
    });
    this.element.dispatchEvent(event);
  }
}
