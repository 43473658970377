import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["colorBar", "colorGrade"];

  connect() {
    this.colorGradeTargets.forEach((color) => {
      this.changeColor(color);
    });
    this.colorBarTargets.forEach((color) => {
      this.changeColor(color);
    });
  }

  changeColor(color) {
    if (
      (color.innerText || color.nextElementSibling.innerText) >= 0 &&
      (color.innerText || color.nextElementSibling.innerText) <= 3
    ) {
      color.classList.add("grade-coaching-3");
    } else if (
      (color.innerText || color.nextElementSibling.innerText) > 3 &&
      (color.innerText || color.nextElementSibling.innerText) <= 6
    ) {
      color.classList.add("grade-coaching-6");
    } else {
      color.classList.add("grade-coaching-10");
    }
  }
}
