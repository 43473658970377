import { Controller } from "stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  connect() {
    this.modal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById("list360Modal"),
    );

    if (!this.modal._element.classList.contains("show")) {
      this.modal.show();
    }
  }
}
