import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["form", "error", "files"];

  submit(e) {
    var form = this.formTarget;
    var action = form.getAttribute("action");
    var method = form.getAttribute("method");
    var formData = new FormData();
    var coacheeVisibility =
      e.target.getAttribute("data-coachee-visibility") || false;
    formData.append("document_uploaded[document]", e.target.files[0]);
    formData.append("document_uploaded[coach_visibility]", true);
    formData.append("document_uploaded[coachee_visibility]", coacheeVisibility);

    form.classList.add("-loading");

    Rails.ajax({
      type: method,
      data: formData,
      dataType: "json",
      processData: false,
      contentType: false,
      url: action,
      success: () => {
        location.reload();
      },
      error: (err) => {
        form.classList.remove("-loading");
        this.errorTarget.append(err.errors[0]);
      },
    });
  }

  coachUpdateVisibility() {
    let data = [];
    Array.from(this.filesTarget.getElementsByTagName("input")).forEach(
      (input) => {
        let documentId = input.getAttribute("data-document-id");
        data.push({ id: documentId, coachee_visibility: input.checked });
      },
    );
    let relationshipUrlToken = this.filesTarget.getAttribute(
      "data-relationship-url-token",
    );
    Rails.ajax({
      type: "PUT",
      data: `document_uploaded[data]=${JSON.stringify({ doc: data })}`,
      contentType: "application/json",
      dataType: "json",
      url: `/relationships/${relationshipUrlToken}/documents/coach_update`,
    });
  }

  update(e) {
    let settings = e.target.getAttribute("data-settings");
    let documentId = e.target.getAttribute("data-document-id");
    Rails.ajax({
      type: "PUT",
      data: `document_uploaded[${settings}]=${e.target.checked}`,
      url: `/relationships/${this.relationshipValue}/documents/${documentId}.json`,
      success: () => {},
    });
  }
}
