import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Search from './search';

export default class Library extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: this.props.dataset.resources,
      selectedResources: []
    };
  }

  selectResource = (resourceId) => {
    const selectedResources = this.state.selectedResources.slice(0);
    if(selectedResources.includes(resourceId)){
      var index = selectedResources.indexOf(resourceId);
      selectedResources.splice(index, 1);
    } else {
      selectedResources.push(resourceId);
    }
    this.setState({selectedResources: selectedResources});
  }

  render() {
    const {relationship_softskill_name, softskills} = this.props.dataset;
    let options = {
      search_filter: relationship_softskill_name,
      relationship_softskill_name,
      softskills
    }

    return(
        <div>
          <Search
              selectedResources={this.state.selectedResources}
              selectResource={this.selectResource}
              resources={this.state.resources}
              resource_locales={this.props.dataset.resource_locales}
              {...options}
          />
        </div>
    );
  }
}
