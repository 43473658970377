import { Controller } from "stimulus";

export default class extends Controller {
  static values = { id: Number, eligible: Boolean, errorMessage: String };
  static targets = ["validate", "cardMessage"];
  connect() {
    if (this.eligibleValue === false) {
      const link = this.validateTarget;
      const p = document.createElement("p");
      p.classList.add("disabled-link");
      p.innerHTML = link.innerHTML;
      link.parentNode.replaceChild(p, link);
      const cardMessage = this.cardMessageTarget;
      const errorMessage = document.createElement("p");
      errorMessage.classList.add("error-message");
      errorMessage.innerHTML = this.errorMessageValue;
      cardMessage.appendChild(errorMessage);
    }
  }
}
