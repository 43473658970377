import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "hidden"];

  initialize() {
    this.inputs = this.inputTargets;
    this.fillEmpty(this.inputs);
  }

  fillEmpty(inputs) {
    inputs.forEach((input) => {
      const inputValue = input.dataset.value;
      if (inputValue) {
        input.value = inputValue;
      }
    });
  }

  update(event) {
    const objectId = Number(event.currentTarget.value);
    this.hiddenTarget.value = objectId;
  }
}
