import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = { href: String, token: String, mark: Boolean, id: String };

  toggleMarkFavorite(e) {
    e.stopImmediatePropagation();

    const target = e.target;
    const mark = target.classList.contains("-active");
    const token = this.tokenValue;
    const idResource = this.idValue;

    const unmarkUrl = "/resources/" + token + "/unmark_as_favorite";
    const markUrl = "/resources/" + token + "/mark_as_favorite";

    const url = mark ? unmarkUrl : markUrl;

    Rails.ajax({
      type: "POST",
      url: url,
      dataType: "html",
      success: () => {
        const selector = document.querySelectorAll(
          ".icon-favorite-" + idResource,
        );
        if (selector) {
          selector.forEach(function (item) {
            item.classList.toggle("-active");
          });
        }
      },
      error: () => {},
    });
  }
}
