import { Controller } from "stimulus";
import Player from "@vimeo/player";

export default class extends Controller {
  static targets = ["iframe"];

  static values = {
    video: Object,
  };

  initialize() {
    this.video = new Player(this.iframeTarget);
  }

  play() {
    this.video.play();
  }

  stop() {
    this.video.unload();
  }
}
