import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "currentPosition",
    "yearPositionDiv",
    "yearPositionLabel",
    "yearInput",
    "submitBtn",
  ];

  connect() {
    this.baseText = this.yearPositionLabelTarget.textContent.trim();
    this.toggleYearPosition();
    this.checkFieldsFilled();
  }

  toggleYearPosition() {
    if (this.currentPositionTarget.value) {
      this.yearPositionDivTarget.style.display = "block";
      const selectedLabel =
        this.currentPositionTarget.options[
          this.currentPositionTarget.selectedIndex
        ].text;
      this.yearPositionLabelTarget.textContent =
        this.getDynamicLabel(selectedLabel);
    } else {
      this.yearPositionDivTarget.style.display = "none";
    }
    this.checkFieldsFilled();
  }

  getDynamicLabel(selectedLabel) {
    return `${this.baseText} ${selectedLabel.toLowerCase()}`;
  }

  checkFieldsFilled() {
    if (this.currentPositionTarget.value && this.yearInputTarget.value) {
      this.submitBtnTarget.disabled = false;
    } else {
      this.submitBtnTarget.disabled = true;
    }
  }
}
