import { Controller } from "stimulus";
import * as time from "@/utils/time";

export default class extends Controller {
  static targets = ["banner"];
  static values = { tz: String };

  connect() {
    if (time.guessTimezone() != this.tzValue) {
      this.bannerTarget.classList.remove("d-none");
    } else {
      this.bannerTarget.remove();
    }
  }
}
