import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)

export function hourLabel(index) {
  if ((index < 0) || (index > 48))
    throw new Error('Index value must be included into [0..48]');

  if (index == 48)
    return dayjs().hour(23).minute(59).format('LT')

  return dayjs().hour(index / 2).minute(index % 2 * 30).format('LT')
}

export function addMinutes(dateIsoString, minutes) {
  return dayjs(dateIsoString).startOf('day').add(parseInt(minutes), 'minute').utc().toISOString()
}

export function shortDate(dateObject) {
  return dayjs(dateObject).format('L')
}

export function minutesFromBeginningOfDay(dateObject) {
  const date = dayjs.tz(dateObject)
  return date.hour() * 60 + date.minute()
}

export function locale(value) {
  return dayjs.locale(value)
}

export function configureLocalizer(localizerBuilder) {
  return localizerBuilder(dayjs)
}

export function startOfWeek(date) {
  return dayjs(date).startOf('week').format()
}

export function endOfWeek(date) {
  return dayjs(date).endOf('week').format()
}

export function guessTimezone(adapter) {
  return (adapter || dayjs).tz.guess()
}

export function setDefaultTimezone(timezone, adapter) {
  return (adapter || dayjs).tz.setDefault(timezone)
}

export function getDateWithTimezone(date) {
  return dayjs.tz(date || new Date())
}
