import { Controller } from "stimulus";

export default class extends Controller {
  openSubMenu(e) {
    if (
      window.innerWidth < 992 &&
      e.target.classList.contains("side-nav__menu-link--mobile-no-link")
    ) {
      e.preventDefault();

      var parent = e.target.parentElement;
      var subMenu = parent.querySelector(".side-nav__sub-menu-list-wrapper");
      var wrapperHeight = subMenu.clientHeight;
      var height = parent.querySelector(
        ".side-nav__sub-menu-list",
      ).clientHeight;

      var newHeight = wrapperHeight === 0 ? height : 0;

      subMenu.classList.toggle("side-nav__sub-menu-list-wrapper--mobile-show");
      subMenu.style.maxHeight = newHeight + "px";

      subMenu.ontransitionend = () => {
        if (newHeight == 0) {
          subMenu.removeAttribute("style");
        }
      };
    }
  }
}
