import { Controller } from "stimulus";
import { useDebounce } from "stimulus-use";

export default class extends Controller {
  static targets = ["checkbox"];

  static debounces = [
    {
      name: "reloadPage",
      wait: 1000,
    },
  ];

  connect() {
    this.checkActiveFilter();
    // debounce only on production to avoid flaky tests
    if (import.meta.env.MODE === "production") {
      useDebounce(this);
    }
  }

  getParams() {
    const url = new URL(window.location.href);
    return new URLSearchParams(url.search);
  }

  getActiveIdsArray() {
    return this.checkboxTargets
      .filter((input) => input.checked)
      .map((checkedInput) => checkedInput.value);
  }

  checkActiveFilter() {
    const checkboxesChecked = this.getParams().getAll("division_ids[]");

    for (const checkbox of this.checkboxTargets) {
      checkbox.checked = !!checkboxesChecked.includes(checkbox.value);
    }
  }

  clearFilter() {
    this.checkboxTargets.forEach((checkbox) => (checkbox.checked = false));
    this.changeFilter();
  }

  changeFilter() {
    let url = new URL(window.location.href);
    let params = this.getParams();

    const currentActiveFilterIds = params.getAll("division_ids[]");
    const newActiveFilterIds = this.getActiveIdsArray();

    if (currentActiveFilterIds != newActiveFilterIds) {
      params.delete("division_ids[]");
      for (const newActiveFilterId of newActiveFilterIds) {
        params.append("division_ids[]", newActiveFilterId);
      }
      url.search = params.toString();
      this.reloadPage(url);
    }
  }

  reloadPage(url) {
    Turbo.visit(url.toString());
  }
}
